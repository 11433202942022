import React, { useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint'; // Importing react-waypoint for visibility detection
import counterUp from 'counterup2'; // Importing counterUp library

const WorkInfo = (props) => {
  // Refs for counters
  const counterRef1 = useRef(null);
  const counterRef2 = useRef(null);
  const counterRef3 = useRef(null);

  // State to track if the animation has been triggered
  const [hasAnimated, setHasAnimated] = useState(false);

  // Function to handle waypoint activation for counter animation
  const handleCounterAnimation = (ref) => {
    if (ref.current && !hasAnimated) {
      counterUp(ref.current, { duration: 1000, delay: 50 });
      setHasAnimated(true); // Set state to prevent further animation
    }
  };

  return (
    <section className={`wrapper ${props.bgClass}`}>
      <div className="container py-5 py-md-16">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="fs-15 text-uppercase text-primary mb-3">
              Company Facts
            </h2>
            <h3 className="display-4 pe-xl-15">We are proud of our works</h3>
          </div>
          <div className="col-lg-8">
            <div className="row align-items-center counter-wrapper gy-6 text-center">
              <div className="col-md-4">
                {/* Waypoint for the first counter */}
                <Waypoint
                  onEnter={() => handleCounterAnimation(counterRef1)}
                  bottomOffset="50%"
                />
                <img
                  src="./assets/img/icons/lineal/check.svg"
                  className="svg-inject icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter" ref={counterRef1}>
                  300 <span>+</span>
                </h3>
                <p className="mb-0">Successful Projects</p>
              </div>
              <div className="col-md-4">
                {/* Waypoint for the second counter */}
                <Waypoint
                  onEnter={() => handleCounterAnimation(counterRef2)}
                  bottomOffset="50%"
                />
                <img
                  src="./assets/img/icons/lineal/user.svg"
                  className="svg-inject icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter" ref={counterRef2}>
                  92.3<span>%</span>
                </h3>
                <p className="mb-0">Client Retention Rate</p>
              </div>
              <div className="col-md-4">
                {/* Waypoint for the third counter */}
                <Waypoint
                  onEnter={() => handleCounterAnimation(counterRef3)}
                  bottomOffset="50%"
                />
                <img
                  src="./assets/img/icons/lineal/briefcase-2.svg"
                  className="svg-inject icon-svg icon-svg-lg text-primary mb-3"
                  alt=""
                />
                <h3 className="counter" ref={counterRef3}>
                  10,000 <span>+</span>
                </h3>
                <p className="mb-0">Hours of Development</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkInfo;
