import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "progressbar.js";
import { Waypoint } from "react-waypoint";

const Stats = () => {
  const progressBar1Ref = useRef(null);
  const progressBar2Ref = useRef(null);
  const [bar1, setBar1] = useState(null);
  const [bar2, setBar2] = useState(null);

  useEffect(() => {
    // Check if refs are available
    if (!progressBar1Ref.current || !progressBar2Ref.current) return;

    const options = {
      strokeWidth: 6,
      trailWidth: 6,
      duration: 2e3,
      easing: "easeInOut",
      color: "#ff00ff",
      trailColor: "#eee",
      text: {
        style: {
          color: "#000",
          position: "absolute",
          left: "50%",
          top: "50%", // Adjusted to give space between text and circle
          padding: 0,
          margin: 0,
          fontSize: "40px", // Increased font size for percentage text
          transform: {
            prefix: true,
            value: "translate(-50%, -50%)", // Centers the text inside the circle
          },
        },
        autoStyleContainer: false,
      },
      svgStyle: {
        width: "100%",
        height: "100%",
      },
    };

    // Create progress bars with even smaller size
    const progressBar1 = new ProgressBar.SemiCircle(progressBar1Ref.current, {
      ...options,
      color: "#E668B3", // Fuchsia color
    });

    const progressBar2 = new ProgressBar.SemiCircle(progressBar2Ref.current, {
      ...options,
      color: "#F78B77", // Orange color
    });

    // Store bar instances in state
    setBar1(progressBar1);
    setBar2(progressBar2);

    // Cleanup function
    return () => {
      progressBar1.destroy();
      progressBar2.destroy();
    };
  }, []);

  const handleProgressBar1Enter = () => {
    if (bar1) {
      bar1.animate(0.95); // 95% for Customer Satisfaction
      bar1.setText("95%");
    }
  };

  const handleProgressBar2Enter = () => {
    if (bar2) {
      bar2.animate(0.8); // 80% for Increased Revenue
      bar2.setText("80%");
    }
  };

  return (
    <section className="wrapper bg-gray">
      <div className="container py-15 py-md-17 pb-md-19">
        <div className="row align-items-center gy-8 mb-13">
          <div className="col-lg-7 text-center text-lg-start">
            <h2 className="fs-16 text-uppercase text-muted mb-3">
              Save Time and Money
            </h2>
            <h3 className="display-3 mb-0 pe-xl-10 pe-xxl-15">
              Save your time and money by choosing our{" "}
              <span className="underline-3 style-2 yellow">professional</span>{" "}
              team.
            </h3>
          </div>
          <div className="col-lg-5">
            <div className="row gy-6 text-center">
              <div className="col-md-6">
                <Waypoint onEnter={handleProgressBar1Enter}>
                  <div
                    ref={progressBar1Ref}
                    style={{
                      position: "relative",
                      height: "90px", // Further reduced size
                      width: "100%",
                    }}
                  />
                </Waypoint>
                <h4 className="mb-0" style={{ marginTop: "15px" }}>
                  Customer Satisfaction
                </h4>{" "}
                {/* Added marginTop */}
              </div>
              <div className="col-md-6">
                <Waypoint onEnter={handleProgressBar2Enter}>
                  <div
                    ref={progressBar2Ref}
                    style={{
                      position: "relative",
                      height: "90px", // Further reduced size
                      width: "100%",
                    }}
                  />
                </Waypoint>
                <h4 className="mb-0" style={{ marginTop: "15px" }}>
                  Increased Revenue
                </h4>{" "}
                {/* Added marginTop */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
