import React,{useState,useRef} from "react";
import { Waypoint } from 'react-waypoint'; // Importing react-waypoint for visibility detection
import counterUp from 'counterup2'; // Importing counterUp library


const Features = () => {
// for the counter thing 
const counterRef1 = useRef(null);
// State to track if the animation has been triggered
  const [hasAnimated, setHasAnimated] = useState(false);

  // Function to handle waypoint activation for counter animation
    const handleCounterAnimation = (ref) => {
      if (ref.current && !hasAnimated) {
        counterUp(ref.current, { duration: 1000, delay: 50 });
        setHasAnimated(true); // Set state to prevent further animation
      }
    };
  return (
    <section className="wrapper bg-gradient-primary">
      <div className="container">
        <div className="row gx-lg-8 gx-xl-12 gy-12 align-items-center mb-10 mb-md-13">
        <div className="col-lg-6 position-relative">
            {/* Waypoint for the counter animation */}
            <Waypoint
              onEnter={() => handleCounterAnimation(counterRef1)}
              bottomOffset="50%" // When 50% of the element is in view, trigger animation
            />
            <div
              className="btn btn-circle btn-primary pe-none position-absolute counter-wrapper flex-column d-none d-md-flex"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "170px",
                height: "170px",
              }}
            >
              <h3 className="text-white mb-1 mt-n2">
                {/* Counter with ref */}
                <span className="counter counter-lg" ref={counterRef1}>
                  10+
                </span>
              </h3>
              <p>Years Experience</p>
            </div>
            <div className="row gx-md-5 gy-5 align-items-center">
              <div className="col-md-6">
                <div className="row gx-md-5 gy-5">
                  <div className="col-md-10 offset-md-2">
                    <figure className="rounded">
                      <img
                        src="./assets/img/illustrations/3d8.png"
                        srcSet="./assets/img/illustrations/3d8@2x.webp 2x"
                        alt="3d8@2x logo"
                      />
                    </figure>
                  </div>
                  <div className="col-md-12">
                    <figure className="rounded">
                      <img
                        src="./assets/img/illustrations/3d4.webp"
                        srcSet="./assets/img/illustrations/3d4@2x.webp 2x"
                        alt="3d4@2x logo"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <figure className="rounded">
                  <img
                    src="./assets/img/illustrations/3d2.png"
                    srcSet="./assets/img/illustrations/3d2@2x.webp 2x"
                    alt="3d2 logo"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h3 className="display-4 mb-5">
              We tailor solutions to amplify your customer's ease and elevate
              your revenue.
            </h3>
            <p className="mb-7">
              At BytezTech, we don't just offer solutions; we engineer
              experiences. From engaging mobile applications to dynamic web
              platforms, our expertise ensures personalized tech solutions that
              resonate with your business goals and customer needs.
            </p>
            <div className="row gy-3">
              <div className="col-xl-6">
                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                  <li>
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>Top-tier industry solutions.</span>
                  </li>
                  <li className="mt-3">
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>
                      Technical Mastery: Leading with modern tech stacks.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-xl-6">
                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                  <li>
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>On-Time Delivery: Quality meets punctuality.</span>
                  </li>
                  <li className="mt-3">
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>
                      Enduring Support: Unmatched post-sale assistance.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row gx-lg-8 gx-xl-12 gy-8 mb-20">
          
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="./assets/img/icons/lineal/browser.svg"
                  className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                  alt="features browser logo"
                />
              </div>
              <div>
                <h4 className="mb-1">Web Development</h4>
                <p className="mb-0">
                  Building scalable and intuitive web platforms that are not
                  only visually appealing but also revenue-driving for your
                  business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="./assets/img/icons/lineal/smartphone-3.svg"
                  className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                  alt="smart phone logo"
                />
              </div>
              <div>
                <h4 className="mb-1">Mobile App Development</h4>
                <p className="mb-0">
                  Crafting user-centric mobile applications designed to
                  captivate your audience and propel your brand in the market.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="./assets/img/icons/lineal/cloud-computing-3.svg"
                  className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                  alt="cloud computing logo"
                />
              </div>
              <div>
                <h4 className="mb-1">Server Management</h4>
                <p className="mb-0">
                  Ensuring optimal server performance with proactive monitoring,
                  so your digital assets remain accessible, secure, and
                  efficient.
                </p>
              </div>
            </div>
          </div>
          
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="./assets/img/icons/lineal/settings.svg"
                  className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                  alt="settings logo"
                />
              </div>
              <div>
                <h4 className="mb-1">Custom Solution</h4>
                <p className="mb-0">
                  Tailored software solutions aligned perfectly with your unique
                  needs, ensuring your business stays ahead of competitors.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="./assets/img/icons/lineal/tools.svg"
                  className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                  alt="tools logo"
                />
              </div>
              <div>
                <h4 className="mb-1">UI/UX Design</h4>
                <p className="mb-0">
                  Merging creativity with functionality, our designs are aimed
                  to provide seamless user experiences while maximizing
                  engagement and conversions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <img
                  src="./assets/img/icons/lineal/telephone-3.svg"
                  className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                  alt="telephone logo"
                />
              </div>
              <div>
                <h4 className="mb-1">24/7 Support</h4>
                <p className="mb-0">
                  Dedicated around-the-clock support, because we understand that
                  your business' success and uptime are paramount to revenue
                  generation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
