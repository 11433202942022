import React, { useState, useEffect } from 'react';

/**
 * Detect if the current device is a mobile device
 * @returns {boolean} True if mobile device, false otherwise
 */
export const isMobileDevice = () => {
  const mobileAgents = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
  ];

  // Check user agent
  const isMobileUserAgent = mobileAgents.some((agent) =>
    navigator.userAgent.match(agent)
  );

  // Additional check for MacIntel with touch support
  const isMacWithTouch =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;

  return isMobileUserAgent || isMacWithTouch;
};

/**
 * Add mobile class to image wrappers
 * @param {string} [selector=".image-wrapper"] - CSS selector for image wrappers
 */
export const applyMobileImageClass = (selector = ".image-wrapper") => {
  if (isMobileDevice()) {
    document.querySelectorAll(selector).forEach((element) => {
      element.classList.add("mobile");
    });
  }
};

/**
 * Get background image based on device type
 * @param {Object} config - Configuration object
 * @param {string} config.desktopImage - Desktop background image
 * @param {string} config.mobileImage - Mobile background image
 * @returns {string} Appropriate background image
 */
export const getResponsiveBackgroundImage = (config) => {
  const { desktopImage, mobileImage } = config;
  return isMobileDevice() ? mobileImage : desktopImage;
};

/**
 * React hook for mobile device detection
 * @returns {boolean} Mobile device status
 */
export const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    const checkMobile = () => setIsMobile(isMobileDevice());

    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return isMobile;
};