import React, { useEffect, useRef } from "react";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import { Link } from "react-router-dom";
import Address from "./Address";
import ImageOverlay from "./hooks/overlay.js";
import { Helmet } from "react-helmet-async";
const Casestudies = () => {
  const gridRef = useRef(null);
  const isotopeRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      // Initialize Isotope
      isotopeRef.current = new Isotope(gridRef.current, {
        itemSelector: ".item", // Ensure you have the correct class
        layoutMode: "masonry",
        percentPosition: true,
        transitionDuration: "0.7s",
      });

      // Handle image loading
      imagesLoaded(gridRef.current, () => {
        isotopeRef.current.layout();
      });

      // Resize event listener
      const handleResize = () => {
        if (isotopeRef.current) {
          isotopeRef.current.layout();
        }
      };

      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
        window.removeEventListener("resize", handleResize);
        if (isotopeRef.current) {
          isotopeRef.current.destroy();
        }
      };
    }
  }, []);

  const handleFilterClick = (filterValue, event) => {
    if (isotopeRef.current) {
      const filterItems = document.querySelectorAll(".filter-item");
      filterItems.forEach((item) => item.classList.remove("active"));
      event.target.classList.add("active");
      isotopeRef.current.arrange({ filter: filterValue });
    }
  };

  return (
    <>
      <Helmet>
        <title>Case Studies Showcasing Success Stories | BytezTech</title>
        <meta
          name="description"
          content="Case studies highlighting innovative solutions and successful projects. Explore how BytezTech delivers impactful results for businesses across industries."
        />
        <meta
          name="keywords"
          content="Case Studies Showcasing Success Stories | BytezTech"
        />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/illustrations/3d12.png"
        />
        <meta
          property="og:title"
          content="Case Studies Showcasing Success Stories | BytezTech"
        />
        <meta
          name="og:description"
          content="Case studies highlighting innovative solutions and successful projects. Explore how BytezTech delivers impactful results for businesses across industries."
        />
        <meta property="og:url" content="https://byteztech.com/casestudies" />
        <link
          rel="canonical"
          href="https://byteztech.com/casestudies "
        />
        <meta
          name="twitter:title"
          content="Case Studies Showcasing Success Stories | BytezTech"
        />
        <meta
          name="twitter:description"
          content="Case studies highlighting innovative solutions and successful projects. Explore how BytezTech delivers impactful results for businesses across industries."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/illustrations/3d12.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Case Studies",
            image:
              "https://www.byteztech.com/assets/img/illustrations/3d12.png",
            description:
              "Case studies highlighting innovative solutions and successful projects. Explore how BytezTech delivers impactful results for businesses across industries.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              ratingCount: "125",
            },
          })}
        </script>
      </Helmet>
      <section className="wrapper bg-light">
        <div className="container pt-10 pt-md-14">
          <div className="row">
            <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
              <h1 className="display-1 mb-3">Projects</h1>
              <p className="lead fs-lg pe-lg-15 pe-xxl-12">
                In-depth analysis of solutions we crafted, showcasing our
                technological prowess.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="grid grid-view projects-masonry">
            <div className="isotope-filter filter mb-10">
              <p>Filter:</p>
              <ul>
                <li>
                  <Link
                    to="#"
                    className="filter-item active"
                    onClick={(e) => handleFilterClick("*", e)}
                  >
                    All
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="filter-item"
                    onClick={(e) => handleFilterClick(".app", e)}
                  >
                    App
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="filter-item"
                    onClick={(e) => handleFilterClick(".web", e)}
                  >
                    Web
                  </Link>
                </li>
              </ul>
            </div>
            <div className="row gx-md-8 gy-10 gy-md-13 isotope" ref={gridRef}>
              <div className="project item col-md-6 col-xl-4 app">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/casestudy-seaqua">
                    <ImageOverlay
                      imageUrl="./assets/img/casestudy/Seaqua.png" // Ensure path is correct
                      alt="Seaqua Case Study"
                      className="img-fluid" // Add 'img-fluid' class for responsiveness
                    >
                      <h5 className="from-top mb-0">Read More</h5>{" "}
                      {/* Text appears on hover */}
                    </ImageOverlay>
                  </Link>
                </figure>

                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            Beauty product
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/casestudy-seaqua">
                            Seaqua
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Empowering Seaqua with digital innovation: from
                          product listing to seamless commission management,
                          enhancing business growth and user satisfaction.
                        </p>
                        <p className="h6">
                          Technology stack -{" "}
                          <span className="post-content">
                            Flutter, Firebase, Laravel, UI/UX design, MySQL
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project item col-md-6 col-xl-4 app">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/asset-control">
                    <ImageOverlay
                      imageUrl="./assets/img/casestudy/Asset-control.png" // Ensure path is correct
                      alt="Asset Control Case Study"
                      className="img-fluid" // Add 'img-fluid' class for responsiveness
                    >
                      <h5 className="from-top mb-0">Read More</h5>{" "}
                      {/* Text appears on hover */}
                    </ImageOverlay>
                  </Link>
                </figure>
                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link
                            to="/asset-control"
                            className="hover"
                            rel="category"
                          >
                            Inventory management
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/asset-control">
                            Asset control
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Discover how BytezTech revolutionized asset management
                          with a barcode-based app, making inventory tracking
                          stress-free and remarkably efficient.
                        </p>
                        <p className="h6">
                          Technology stack -{" "}
                          <span className="post-content">
                            Flutter, Firebase, Laravel, UI/UX design, MySQL
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project item col-md-6 col-xl-4 web">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/amazing-car-rental">
                    <ImageOverlay
                      imageUrl="./assets/img/casestudy/Amaze-car.png"
                      alt="Amazing Car Rental Case Study"
                      className="img-fluid"
                    >
                      <h5 className="from-top mb-0">Read More</h5>{" "}
                      {/* Text appears on hover */}
                    </ImageOverlay>
                  </Link>
                </figure>
                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            Travel and transportation
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/amazing-car-rental">
                            Amazing car rental
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Experience ease and transparency in Dubai car rentals
                          with our innovative web solution, setting a new
                          benchmark in customer satisfaction.
                        </p>
                        <p className="h6">
                          Technology stack -{" "}
                          <span className="post-content">
                            Php, Laravel, UI/UX design, MySQL, Google analytics
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project item col-md-6 col-xl-4 web">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/royal-car">
                    <ImageOverlay
                      imageUrl="./assets/img/casestudy/royal-car.png" // Path to Royal Car image
                      alt="Royal Car"
                    >
                      <h5 className="from-top mb-0">Read More</h5>{" "}
                      {/* Customized text */}
                    </ImageOverlay>
                  </Link>
                </figure>
                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            Used car selling
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/royal-car">
                            Royal Car
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          BytezTech revolutionizes Car Mela: from chaotic
                          management to streamlined operations, fostering growth
                          and enhanced customer satisfaction through tailored
                          solutions.
                        </p>
                        <p className="h6">
                          Technology stack -{" "}
                          <span className="post-content">
                            PHP, Laravel, Vue.js, UI/UX design, MySQL
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
};

export default Casestudies;
