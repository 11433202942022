import React from "react";
import { Link } from "react-router-dom";
import ModalButton from "./reactModal/ModalButton";
import { TypeAnimation } from "react-type-animation";

const Cover = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container pt-10 pt-md-14 pb-14 pb-md-16 text-center">
        <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 align-items-center">
          <div className="col-lg-7 order-lg-2">
            <figure>
              <img
                className="img-auto"
                src="./assets/img/illustrations/i2.webp"
                srcSet="./assets/img/illustrations/i2@2x.webp 2x"
                alt="cover_image"
                title="cover_image"
                fetchpriority="high"
              />
            </figure>
          </div>
          <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
            <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">
              BytezTech focuses on <br />
              <TypeAnimation
                sequence={[
                  "App development", // First text
                  2000, // Pause for 2 seconds
                  "Web development", // Second text
                  2000, // Pause for 2 seconds
                  "Custom solution", // Third text
                  2000, // Pause for 2 seconds
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                className="text-primary"
              />
            </h1>
            <p className="lead fs-lg mb-7">
              Crafting mobile, web, and custom solutions, we're your dedicated
              tech ally, ensuring every step aligns with your vision and goals.
            </p>
            <Link
              to={"/application-development"}
              className="btn btn-sm btn-primary rounded"
            >
              Hire us
            </Link>
            <ModalButton buttonValue={"Get a free quote"} />
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="divider text-soft-primary mx-n2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
            <path
              fill="currentColor"
              d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default Cover;
