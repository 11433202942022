import React, { useEffect, useRef, useState } from "react";
import Address from "../Address";
import { Link } from "react-router-dom";
import ModalButton from "../reactModal/ModalButton";
import ProgressBar from "progressbar.js";
import { Waypoint } from "react-waypoint";
import counterUp from "counterup2";
import { Helmet } from "react-helmet-async";
import SwiperSlider from "../hooks/swiperslider.js";

const RoyalCar = () => {
  const backgroundImageStyles = {
    banner: {
      backgroundImage: "url('./assets/img/casestudy/royal-banner0.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    card: {
      backgroundImage: "url('./assets/img/photos/bg16.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      opacity: 0.8, // Optional: to add an overlay effect
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // for progress thing
  const progressBarRef = useRef(null);
  const [bar, setBar] = useState(null);

  useEffect(() => {
    // Check if the ref is available
    if (!progressBarRef.current) return;

    const options = {
      strokeWidth: 6, // Reduced stroke width
      trailWidth: 6, // Reduced trail width
      duration: 2000,
      easing: "easeInOut",
      color: "#E668B3", // Fuchsia color
      trailColor: "#eee",
      text: {
        style: {
          color: "#000",
          position: "absolute",
          left: "50%",
          top: "50%",
          padding: 0,
          margin: 0,
          fontSize: "40px", // Smaller font size for the percentage text
          fontWeight: "bold", // Make the percentage text bold
          transform: {
            prefix: true,
            value: "translate(-50%, -50%)",
          },
        },
        autoStyleContainer: false,
      },
      svgStyle: {
        width: "100%",
        height: "100%",
      },
    };

    // Create the progress bar instance
    const progressBar = new ProgressBar.SemiCircle(progressBarRef.current, options);

    // Store the bar instance in state
    setBar(progressBar);

    // Cleanup function
    return () => {
      progressBar.destroy();
    };
  }, []);

  const handleProgressBarEnter = () => {
    if (bar) {
      bar.animate(0.6); // 60% for the Time Saved
      bar.setText("90");
    }
  };

  // Refs for counters
  const counterRef1 = useRef(null);
  // State to track if the animation has been triggered
  const [hasAnimated, setHasAnimated] = useState(false);

  // Function to handle waypoint activation for counter animation
  const handleCounterAnimation = (ref) => {
    if (ref.current && !hasAnimated) {
      counterUp(ref.current, { duration: 1000, delay: 50 });
      setHasAnimated(true); // Set state to prevent further animation
    }
  };

  //slider thing
  const TechnologyStack = [
    {
      src: "./assets/img/icons/custom/php.svg",
      alt: "PHP",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/solid/laravel.svg",
      alt: "Laravel",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/custom/uiux.svg",
      alt: "UI/UX",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/custom/mysql.svg",
      alt: "MySQL",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/custom/googleanalytics.svg",
      alt: "Google Analytics",
      className: "icon-svg icon-svg-md",
    },
  ];
  //Royal car images
  // Sequa images
  const slidesData = [
    {
      src: "./assets/img/Carmela/Carmela1.png",
      alt: "Amazing Car 1",
    },
    {
      src: "./assets/img/Carmela/Carmela2.png",
      alt: "Amazing Car Project Image 2",
    },
    {
      src: "./assets/img/Carmela/Carmela3.png",
      alt: "Amazing Car Project Image 3",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Royal Car Management Solutions | BytezTech</title>
        <meta
          name="description"
          content="Royal car management solutions crafted for luxury and efficiency. BytezTech delivers innovative systems to enhance your car rental and fleet operations seamlessly."
        />
        <meta name="keywords" content="Royal Car Management Solutions | BytezTech" />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/Carmela/Carmela2.png"
        />
        <meta property="og:title" content="Royal Car Management Solutions | BytezTech" />
        <meta
          name="og:description"
          content="Royal car management solutions crafted for luxury and efficiency. BytezTech delivers innovative systems to enhance your car rental and fleet operations seamlessly."
        />
        <meta property="og:url" content="https://byteztech.com/royal-car" />
        <link rel="canonical" href="https://byteztech.com/royal-car" />
        <meta name="twitter:title" content="Royal Car Management Solutions | BytezTech" />
        <meta
          name="twitter:description"
          content="Royal car management solutions crafted for luxury and efficiency. BytezTech delivers innovative systems to enhance your car rental and fleet operations seamlessly."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/Carmela/Carmela2.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Royal car",
            image: "https://www.byteztech.com/assets/img/Carmela/Carmela2.png",
            description:
              "Royal car management solutions crafted for luxury and efficiency. BytezTech delivers innovative systems to enhance your car rental and fleet operations seamlessly.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.4",
              ratingCount: "126",
            },
          })}
        </script>
      </Helmet>
      <section
        style={backgroundImageStyles.banner}
        className="wrapper image-wrapper bg-overlay text-white"
      >
        <div className="container pt-17 pb-12 pt-md-19 pb-md-16 text-center">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link href="#" className="text-reset" rel="category">
                    Used car selling
                  </Link>
                </div>
                <h1 className="display-1 mb-3 text-white">
                  Revolutionizing the Car Mela B2B Marketplace
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/challenges.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt="illustration of challanges"
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The challenge faced by Royal Car system is...
              </h2>
            </div>
          </div>
          <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card me-lg-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">01</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Cluttered Documentation</h4>
                      <p className="mb-0">
                        The avalanche of purchase and repair histories made management a headache.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-lg-13 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">02</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Financial Blur</h4>
                      <p className="mb-0">
                        It was increasingly challenging to clearly discern profits and losses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-lg-6 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">03</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Disjointed Sales Records</h4>
                      <p className="mb-0">
                        Keeping a clear record of each sale was becoming an uphill battle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">The Challenge</h2>
              <p className="lead fs-lg pe-lg-5">
                Streamlining vehicle bookings while enhancing user experience and car availability.
              </p>
              <p>
                In a bustling corner of the city, the used car marketplace thrived. However,
                managing a vast inventory of over a thousand cars wasn't an easy feat. Documents
                piled up, and the owner struggled to keep track of each car's history, from purchase
                and repairs to eventual sales. The overwhelming paperwork and the chaotic process of
                tracking profits and losses needed a major overhaul.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pt-14 pb-14 pb-md-18">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/solutions.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt="illustration of solutions"
              />
              <h2 className="display-4 mb-4 px-lg-14">The solution we offer is ...</h2>
            </div>
          </div>

          <div className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center">
            <div className="col-lg-5 offset-lg-1">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/3d2.png"
                  srcSet="./assets/img/illustrations/3d2@2x.png 2x"
                  alt="illustration of 3d2"
                />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">The Solution</h2>
              <h3 className="display-5 mb-4">
                We bring solutions to make life easier to manage the assets.
              </h3>
              <p className="mb-6">
                Understanding the dire need for streamlined management, BytezTech stepped in with a
                comprehensive solution. We developed a robust system that consolidated every vital
                piece of information in one accessible platform. Now, tracking a car's history, from
                its acquisition, repairs, and additional costs, to its eventual sale became a
                hassle-free process. The owner could now easily view detailed reports, graphs, and
                insightful data, giving a clear picture of the business's financial health.
              </p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Unified Car Records</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Transparent Financial Insights</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Streamlined Sales Management</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-md-8 gy-10 align-items-center">
            <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
              <figure className="rounded">
                <img
                  className="img-fluid"
                  src="./assets/img/photos/about27.jpg"
                  srcSet="./assets/img/photos/about27@2x.jpg 2x"
                  alt="illustration of about"
                />
              </figure>
              <div
                className="card shadow-lg position-absolute d-none d-md-block"
                style={{ top: "15%", left: "-7%" }}
              >
                <div className="card-body py-4 px-5">
                  <div className="d-flex flex-row align-items-center">
                    <div>
                      <img
                        src="./assets/img/icons/solid/cloud-group.svg"
                        className="svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-3"
                        alt="illustration of cloud group"
                      />
                    </div>
                    <div>
                      <Waypoint
                        onEnter={() => handleCounterAnimation(counterRef1)}
                        bottomOffset="50%"
                      />
                      <h3 className="fs-25 counter mb-0 text-nowrap" ref={counterRef1}>
                        43+ {/* Initial value */}
                      </h3>
                      <p className="fs-16 lh-sm mb-0 text-nowrap">Happy Owners</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card shadow-lg position-absolute text-center d-none d-md-block"
                style={{ bottom: "10%", left: "-10%" }}
              >
                <div className="card-body p-6">
                  <Waypoint onEnter={handleProgressBarEnter}>
                    <div
                      ref={progressBarRef}
                      className="progressbar semi-circle fuchsia mb-3"
                      style={{
                        position: "relative",
                        height: "90px",
                        width: "90%",
                      }}
                    />
                  </Waypoint>
                </div>
                <h4
                  className="mb-0"
                  style={{
                    fontWeight: "bold",
                    position: "relative",
                    top: "-25px", // Adjust this value for more or less space below the semi-circle
                    textAlign: "center", // Centers the text below the circle
                  }}
                >
                  Time Saved
                </h4>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                <img
                  src="./assets/img/icons/custom/results.svg"
                  className="svg-inject icon-svg icon-svg-md mb-4"
                  alt="illustration of results"
                />
                <h2 className="display-4 mb-4 px-lg-14">The result it brings...</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">The Result</h2>
              <p className="mb-6">
                With BytezTech's intervention, Used car selling and buying transformed into a
                well-oiled machine. The owner could now effortlessly manage the inventory, track the
                sales process, and get a clear view of the financial aspects with ease. The business
                blossomed, as the owner could now focus on strategic growth rather than getting
                bogged down with administrative hassles.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-primary">
                <li>
                  <i className="uil uil-check"></i>
                  <span className="result-span">Simplified Operations: </span>
                  No more chaos; just a smooth, streamlined process that boosted efficiency.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span className="result-span">Clear Financial Picture: </span>
                  With organized data, determining profits and losses became straightforward and
                  quick.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span className="result-span">Enhanced Business Growth: </span>
                  The owner could now focus on scaling the business, armed with insights and
                  clarity, paving the way for greater success and customer satisfaction.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <p className="text-center display-3 mb-8">Technology Stack</p>
          <SwiperSlider
            slides={TechnologyStack.map((tech, index) => (
              <img
                key={index}
                src={tech.src}
                alt={tech.alt}
                className={tech.className}
                style={{
                  width: "52px", // Set a fixed width for the images
                  height: "auto", // Maintain aspect ratio
                  margin: "auto", // Center images
                }}
              />
            ))}
            spaceBetween={50} // Space between slides
            slidesPerView={7} // Number of visible slides
            loop={true} // Enable looping
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={false} // Disable dots (pagination)
            breakpoints={{
              320: { slidesPerView: 2, spaceBetween: 10 },
              640: { slidesPerView: 3, spaceBetween: 20 },
              1024: { slidesPerView: 5, spaceBetween: 30 },
            }}
            nav={false} // Disable navigation buttons
          />
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pb-13 pb-md-15">
          <div
            className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-300 mb-14"
            style={backgroundImageStyles.card}
          >
            <div className="card-body p-10 p-xl-12">
              <div className="row text-center">
                <div className="col-xl-11 col-xxl-9 mx-auto">
                  <h2 className="fs-16 text-uppercase text-white mb-3">Book a free consultation</h2>
                  <h3 className="display-3 mb-8 px-lg-8 text-white">
                    Unlock personalized advice with a{" "}
                    <span className="underline-3 style-2 yellow">complimentary consultation.</span>{" "}
                    Book your slot now!
                  </h3>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <span>
                  <ModalButton buttonValue={"Book a free consultation"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="wrapper bg-light">
        <div className="container-fluid px-md-6">
          <div
            className="swiper-container swiper-height blog grid-view mb-17 mb-md-19"
            data-margin="30"
            data-nav="true"
            data-dots="true"
            data-items-xxl="2"
            data-items-md="2"
            data-items-xs="1"
          >
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img
                      src="./assets/img/Carmela/Carmela1.png"
                      alt="illustration of carmela 1"
                    />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img
                      src="./assets/img/Carmela/Carmela2.png"
                      alt="illustration of carmlea 2"
                    />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img
                      src="./assets/img/Carmela/Carmela3.png"
                      alt="illustration of carmela 3"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="wrapper bg-light mb-lg-15">
        <div className="container-fluid px-md-6 mb-lg-6">
          <SwiperSlider
            slides={slidesData.map((slide, index) => (
              <div
                key={index}
                className="swiper-slide"
                style={{
                  display: "flex",
                  paddingBottom: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <figure className="rounded">
                  <img
                    src={slide.src}
                    alt={slide.alt}
                    className="w-100"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </figure>
              </div>
            ))}
            spaceBetween={30}
            slidesPerView={2}
            pagination={{
              clickable: true,
              type: "bullets",
              renderBullet: function (index, className) {
                return `<span class="${className}"></span>`;
              },
            }}
            navigation={true}
            loop={false}
            autoplay={{ delay: 5000 }}
            nav={true}
            breakpoints={{
              320: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
            }}
            style={{
              paddingBottom: "90px",
            }}
          />
        </div>
      </section>
      <Address />
    </>
  );
};

export default RoyalCar;
