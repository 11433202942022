import React, { useEffect } from "react";
import Tech from "./Tech";
import Address from "../Address";
import ModalButton from "../reactModal/ModalButton";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const Appservice = () => {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Mobile App Solution | BytezTech’s Expert App Development</title>
        <meta
          name="description"
          content="Mobile app solution tailored to your needs. BytezTech delivers innovative, user-friendly app development to help your business thrive in the digital age."
        />
        <meta
          name="keywords"
          content="Mobile App Solution | BytezTech’s Expert App Development"
        />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/illustrations/project-app-1.png"
        />
        <meta
          property="og:title"
          content="Mobile App Solution | BytezTech’s Expert App Development"
        />
        <meta
          name="og:description"
          content="Mobile app solution tailored to your needs. BytezTech delivers innovative, user-friendly app development to help your business thrive in the digital age."
        />
        <meta
          property="og:url"
          content="https://byteztech.com/mobile-app-solution  "
        />
        <link
          rel="canonical"
          href="https://byteztech.com/mobile-app-solution  "
        />
        <meta
          name="twitter:title"
          content="Mobile App Solution | BytezTech’s Expert App Development"
        />
        <meta
          name="twitter:description"
          content="Mobile app solution tailored to your needs. BytezTech delivers innovative, user-friendly app development to help your business thrive in the digital age."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/illustrations/project-app-1.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Custom Solution",
            image:
              "https://www.byteztech.com/assets/img/illustrations/project-app-1.png",
            description:
              "Mobile app solution tailored to your needs. BytezTech delivers innovative, user-friendly app development to help your business thrive in the digital age.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.6",
              ratingCount: "127",
            },
          })}
        </script>
      </Helmet>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 mx-auto text-center">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                Why Choose BytezTech?
              </h2>
              <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">
                Here are a few reasons why our customers choose BytezTech.
              </h3>
            </div>
          </div>
          <ul className="nav nav-tabs nav-tabs-bg d-flex justify-content-between nav-justified flex-lg-row flex-column">
            <li className="nav-item">
              {" "}
              <a
                className={`nav-link d-flex flex-row ${
                  path === "/mobile-app-solution" ? "active" : ""
                }`}
                data-bs-toggle="tab"
                href="#tab2-1"
              >
                <div>
                  <img
                    src="./assets/img/icons/custom/appdeveloping.svg"
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="illustration of appdeveloping"
                  />
                </div>
                <div>
                  <h4>App Development</h4>
                  <p>
                    We provide an endless variety of app development with
                    innovative ideas of developing apps and increasing the user
                    experience which can lead to user satisfaction and increased
                    revenue for your business. Choose us to identify the best
                    possible solutions for your app development and provide the
                    right tools for you.
                  </p>
                </div>
              </a>{" "}
            </li>
            <li className="nav-item">
              {" "}
              <a
                className={`nav-link d-flex flex-row ${
                  path === "/web-solution" ? "active" : ""
                }`}
                data-bs-toggle="tab"
                href="#tab2-2"
              >
                <div>
                  <img
                    src="./assets/img/icons/custom/webdevloping.svg"
                    className="svg-inject icon-svg icon-svg-md text-green me-4"
                    alt="illustration of web developing"
                  />
                </div>
                <div>
                  <h4>Web Development</h4>
                  <p>
                    At BytezTech we provide you with the best redefining
                    Websites ideas which can create and increase your revenue
                    growth as well as will be pleased with aesthetic design and
                    functionality this will ensure better crowd engagement with
                    your business and helps to identify the best possible
                    solution to your business.
                  </p>
                </div>
              </a>{" "}
            </li>
            <li className="nav-item">
              {" "}
              <a
                className={`nav-link d-flex flex-row ${
                  path === "/custom-solution" ? "active" : ""
                }`}
                data-bs-toggle="tab"
                href="#tab2-3"
              >
                <div>
                  <img
                    src="./assets/img/icons/custom/customsol.svg"
                    className="svg-inject icon-svg icon-svg-md text-red me-4"
                    alt="illustration of customsol"
                  />
                </div>
                <div>
                  <h4> Custom Solution</h4>
                  <p>
                    We can also create a custom solution depending upon your
                    requirement and provide the best possible outcomes which can
                    build a unique brand presence as well as create revenue by
                    choosing the right custom solutions.
                  </p>
                </div>
              </a>{" "}
            </li>
          </ul>
          <div className="tab-content mt-6 mt-lg-8">
            <div
              className={`tab-pane fade ${
                path === "/mobile-app-solution" ? "show active" : ""
              }`}
              id="tab2-1"
            >
              <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-lg-6">
                  <div className="row gx-md-5 gy-5 align-items-center">
                    <div className="col-6">
                      <img
                        className="img-fluid rounded shadow-lg d-flex ms-auto"
                        src="./assets/img/illustrations/project-app-1.png"
                        srcSet="./assets/img/illustrations/project-app-1.png"
                        alt="illustration of project app 1"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="img-fluid rounded shadow-lg mb-5"
                        src="./assets/img/illustrations/project-app-2.png"
                        srcSet="./assets/img/illustrations/project-app-2.png"
                        alt="illustration of project app 2"
                      />
                      <img
                        className="img-fluid rounded shadow-lg d-flex col-10"
                        src="./assets/img/illustrations/project-app-3.png"
                        srcSet="./assets/img/illustrations/project-app-3.png"
                        alt="illustration of project app 3"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h2 className="mb-3">Mobile app development</h2>
                  <p>
                    By choosing Our Custom Mobile Application Development
                    Services you will be able to generate revenues by implying
                    new and modern solutions of tech.
                  </p>
                  <p>
                    We provide the best and optimised solutions which will help
                    and increase the business growth.
                  </p>
                  <p>
                    We encourage your ideas and vision and provide our best
                    possible solutions to implement it to your business
                    strategies and increase productivity and audience.
                  </p>
                  <p>
                    We provide different designs for your app development which
                    can stand out from the other apps present. We understand
                    your goals and planning. We curate an innovative solution to
                    your application which can transform ideas into
                    revenue-generating tools.
                  </p>
                  <h3 className="mb-3">Our aim?</h3>
                  <p>
                    We aim to provide the best user experience to your website
                    and application. We make sure that your business stands out
                    from the competition and gets the right audience to you.
                  </p>
                  <p>
                    We create applications which can prove to be an asset for
                    your business and helps you to earn revenue from it.
                  </p>
                </div>
              </div>
              <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                  <div className="row d-flex align-items-start gy-10">
                    <div
                      className="col-lg-5 position-lg-sticky"
                      style={{ top: "8rem" }}
                    >
                      <h3 className="display-2 mb-5">
                        The service we offer is specifically designed to meet
                        your needs.
                      </h3>
                      <p>
                        We provide mobile app development services which can
                        help your business by standing out from the crowd and
                        having unique designs and features customised according
                        to your needs.
                      </p>
                      <p className="mb-7">
                        Our team always looks for the best possible solutions to
                        your inspiring ideas and provide experts to work with
                        dedication on your business and yearn a good response
                        from audience which will ensure sustained digital
                        success for your business.
                      </p>
                      <ModalButton buttonValue={"Share your business need"} />
                    </div>
                    <div className="col-lg-6 ms-auto">
                      <div className="card bg-soft-fuchsia mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="assets/img/icons/solid/android.svg"
                              className="svg-inject icon-svg icon-svg-md text-fuchsia me-5"
                              alt="illustration of android"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              Android app development
                            </h3>
                            <p className="mb-0">
                              With this ever evolving technology we at
                              BytezTech, provides you with the best Android
                              expertise that goes beyond code. With professional
                              teams of experts we provide a seamless experience
                              to your app development as we are committed to
                              your vision and provide our best possible
                              solutions. We can help you to stand out in this
                              dynamic world of technology which can aid your
                              business and convert leads into clients.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-aqua mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="assets/img/icons/solid/ios.svg"
                              className="svg-inject icon-svg icon-svg-md text-aqua me-5"
                              alt="illustration of ios"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">iOS app development</h3>
                            <p className="mb-0">
                              BytezTech aims to provide the best IOS app
                              development which can enhance the user experience
                              as well as create a distinct user designs from
                              other mainstream. We can make your vision into an
                              application which can impact your brand's presence
                              in the Apple ecosystem. Choosing IOS app
                              development will aim your business to target
                              specific users and increases the engagement rate.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-yellow mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="assets/img/icons/solid/flutter.svg"
                              className="svg-inject icon-svg icon-svg-md text-yellow me-5"
                              alt="illustration of flutter"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              Flutter app development
                            </h3>
                            <p>
                              In order to ensure the hybrid app landscape we
                              also provide the flutter app development which can
                              ensure that your business delivers versatility and
                              provides a smooth and reliable experience to the
                              users available on both IOS and android.
                            </p>
                            <p className="mb-0">
                              The flutter app development also increases the
                              scope of reaching more audience in a given amount
                              of time and increases your company's vision and
                              revenue.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-leaf mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="assets/img/icons/solid/reactnative.svg"
                              className="svg-inject icon-svg icon-svg-md text-leaf me-5"
                              alt="illustration of reactnative"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              React Native app development
                            </h3>
                            <p>
                              We at BytezTech also provide react native app
                              development which provides a framework to your
                              application as well as provides a useful
                              experience to the users available on both IOS and
                              android.
                            </p>
                            <p className="mb-0">
                              This ensures wide availability of users and
                              increases the exposure of your business. This is
                              an efficient app development technique to achieve
                              success with a wider audience engagement in a
                              suitable user environment.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-orange mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/apptest.svg"
                              className="svg-inject icon-svg icon-svg-md text-orange me-5"
                              alt="illustration of apptest"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">Mobile app testing</h3>
                            <p>
                              It is important for your business to provide a
                              reliable experience to users. Thus at BytezTech's
                              mobile app testing suite, we provide mobile app
                              testing which can help you to target and know the
                              stronger features of your application and know
                              about the interface your business will provide to
                              the new and existing users.
                            </p>
                            <p className="mb-0">
                              We ensure that your application provides a unique
                              experience as well as different designs depending
                              upon your requirements and contribute to the
                              digital ecosystem with leading features. With our
                              application tests we make sure that you have
                              optimal functionality and provide stability to
                              your business by having a flawless user experience
                              for the audience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              className={`tab-pane fade ${
                path === "/web-solution" ? "show active" : ""
              }`}
              id="tab2-2"
            >
              <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-lg-6 order-lg-2">
                  <div className="row gx-md-5 gy-5">
                    <div className="col-5">
                      <img
                        className="img-fluid rounded shadow-lg my-5 d-flex ms-auto"
                        src="./assets/img/illustrations/project-web-1.png"
                        srcSet="./assets/img/illustrations/project-web-1.png 2x"
                        alt="illustration of web-1 "
                      />
                      <img
                        className="img-fluid rounded shadow-lg d-flex col-10 ms-auto"
                        src="./assets/img/illustrations/project-web-3.png"
                        srcSet="./assets/img/illustrations/project-web-3.png 2x"
                        alt="illustration of web-3"
                      />
                    </div>
                    <div className="col-7">
                      <img
                        className="img-fluid rounded shadow-lg mb-5"
                        src="./assets/img/illustrations/project-web-2.png"
                        srcSet="./assets/img/illustrations/project-web-2.png 2x"
                        alt="illustration of web-2"
                      />
                      <img
                        className="img-fluid rounded shadow-lg d-flex col-11"
                        src="./assets/img/illustrations/project-web-4.png"
                        srcSet="./assets/img/illustrations/project-web-4.png 2x"
                        alt="illustration of web-4"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h2 className="mb-3">Web app development</h2>
                  <p>
                    We at BytezTech provide you services that can get you a
                    unique brand presence on the web as well stand you out from
                    the rest of the crowd.
                  </p>
                  <p>
                    We understand the importance of having a website in this
                    modern era of tech thus we provide the best possible
                    solution to increase as well as maintain the crowd
                    engagement of your business.
                  </p>
                  <p>
                    A decent creative website can bring a lot of new business as
                    well as maintain existing clients by providing a sense of
                    community to them. This will help you to build a brand
                    presence which can boost your brand sales.
                  </p>
                  <p>
                    We at BytezTech provide you a dynamic and responsive website
                    which can definitely be used as your brand icon.
                  </p>
                  <h3 className="mb-3">Our objective?</h3>
                  <p>
                    We are here to provide and enhance the quality of your
                    existing brand and website which can help you to drive more
                    users engagement as well as help you to build a strong
                    presence online.
                  </p>
                  <p>
                    Choosing BytezTech will improve your brand success
                    trajectory and bring a brighter future to your company.
                  </p>
                </div>
              </div>
              <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                  <div className="row d-flex align-items-start gy-10">
                    <div
                      className="col-lg-5 position-lg-sticky"
                      style={{ top: "8rem" }}
                    >
                      <h3 className="display-2 mb-5">
                        The service we offer is specifically designed to meet
                        your needs.
                      </h3>
                      <p>
                        We conduct the proper examination regarding your
                        existing website and propose ideas which can be the
                        possible solutions for it.
                      </p>
                      <p>
                        We have a team of professional web developers which are
                        dedicated to design and put their best to deliver the
                        potential outcome from it.
                      </p>
                      <p className="mb-7">
                        Having a dedicated team of developers we make sure that
                        you achieve long-term support and sustained digital
                        success of your company.
                      </p>
                      <ModalButton buttonValue={"Share your business need"} />
                    </div>
                    <div className="col-lg-6 ms-auto">
                      <div className="card bg-soft-fuchsia mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/webdevelop.svg"
                              className="svg-inject icon-svg icon-svg-md text-fuchsia me-5"
                              alt="illustration of webdevelop"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">Website development</h3>
                            <p>
                              Choose us for your website development journey we
                              look to provide the best possible solution to your
                              existing website and create the best out of it.
                            </p>
                            <p>
                              Our experienced developers deliver you the best
                              layout and structure for your website.
                            </p>
                            <p className="mb-0">
                              We choose to create the best digital website which
                              can empower your brand presence and help you to
                              get a better crowd engagement. By Choosing us you
                              will choose to build a future ready website which
                              can continue to contribute to your wealth and
                              success.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-aqua mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/ecommdev.svg"
                              className="svg-inject icon-svg icon-svg-md text-aqua me-5"
                              alt="illustration of ecommdev"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              e-commerce development
                            </h3>
                            <p>
                              Choose BytezTech to get the best of the digital
                              marketplace and get the desired e-commerce
                              solutions. The experienced team of developers
                              helps to create a better ecommerce experience for
                              your clients which can aid them to trust your
                              brand more.
                            </p>
                            <p className="mb-0">
                              At BytezTech we will provide your customers a
                              seamless shopping experience by providing correct
                              transaction conversion.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-yellow mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/merndev.svg"
                              className="svg-inject icon-svg icon-svg-md text-yellow me-5"
                              alt="illustration of merndev"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              MERN stack development
                            </h3>
                            <p>
                              MERN stack development, is basically a collection
                              of other software languages like MongoDB,
                              Express.js, React, and Node.js. We at BytezTech
                              provide you the best dynamic web dynamic by using
                              the MERN stack.
                            </p>
                            <p className="mb-0">
                              We have a team of experts who are able to produce
                              the best results creating responsive and detailed
                              architect solutions. We provide a dedicated team
                              of experts to work on your ideas and build a
                              better user experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-leaf mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/solid/python.svg"
                              className="svg-inject icon-svg icon-svg-md text-leaf me-5"
                              alt="ilustration of python"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">Python development</h3>
                            <p>
                              We at BytezTech provide the best python oriented
                              development which can stand out from the other web
                              applications and deliver a strong online presence.
                            </p>
                            <p className="mb-0">
                              The Python oriented development services that
                              cater to modern business needs. Our experienced
                              engineers provide the best scalable and
                              forward-compatible solutions to your website and
                              prioritise a stable platform for your business.At
                              BytezTech we support your ideas of creating your
                              brand unique and we provide our best possible
                              solutions to bring your ideas to reality.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-orange mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="assets/img/icons/solid/laravel.svg"
                              className="svg-inject icon-svg icon-svg-md text-orange me-5"
                              alt="illustration of laravel"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">Laravel development</h3>
                            <p className="mb-0">
                              With our laravel development we make sure that you
                              have the best scalable web solutions which will be
                              dedicated to deliver the best possible outcomes.
                              With the collective use of laravel syntax and
                              other features we provide the best web
                              applications which can provide profit to your
                              business which will help you a smooth and
                              efficient user experiences.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              className={`tab-pane fade ${
                path === "/custom-solution" ? "show active" : ""
              }`}
              id="tab2-3"
            >
              <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-lg-6">
                  <div className="row gx-md-5 gy-5">
                    <div className="col-6">
                      <img
                        className="img-fluid rounded shadow-lg mb-5"
                        src="./assets/img/illustrations/project-cus-1.png"
                        srcSet="./assets/img/illustrations/project-cus-1.png 2x"
                        alt="illustration of project cus-1"
                      />
                      <img
                        className="img-fluid rounded shadow-lg d-flex col-10 ms-auto"
                        src="./assets/img/illustrations/project-cus-3.png"
                        srcSet="./assets/img/illustrations/project-cus-3.png 2x"
                        alt="illustration of cus-3"
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="img-fluid rounded shadow-lg my-5"
                        src="./assets/img/illustrations/project-cus-4.png"
                        srcSet="./assets/img/illustrations/project-cus-4.png 2x"
                        alt="illustration of cus-4"
                      />
                      <img
                        className="img-fluid rounded shadow-lg d-flex col-10"
                        src="./assets/img/illustrations/project-cus-2.png"
                        srcSet="./assets/img/illustrations/project-cus-2.png 2x"
                        alt="illustration of cus-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h2 className="mb-3">Custom solution</h2>
                  <p>
                    We at BytezTech provide you with the best solutions towards
                    your software development. We aim to convert your innovative
                    software ideas into reality with the help of dynamic SaaS
                    software solutions. We have a team which has IoT
                    experiences. We aim to develop live streaming platforms
                    which can help you to achieve and improve the connections
                    with your clients.
                  </p>
                  <p>
                    We are here to provide you with the best services which can
                    ensure that your business grows and achieves new heights of
                    success.
                  </p>
                  <p>
                    With innovative chatbot solutions which will help your
                    business to engage online audiences and attract more crowds
                    to it.
                  </p>
                  <p>
                    We at BytezTech also aim to provide the best of service
                    which can create and open new paths of glory to your
                    company. With us you can blossom your business tactics and
                    create a new and better relationship with the internet
                    world.
                  </p>
                  <p>
                    We also help you to resonate with your audience and bring
                    new ideas to the business as it will increase your business
                    revenue growth. Our team of experienced professionals will
                    develop your vision into reality and create an environment
                    of growth and bring more wealth to your business.
                  </p>
                </div>
              </div>
              <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                  <div className="row d-flex align-items-start gy-10">
                    <div
                      className="col-lg-5 position-lg-sticky"
                      style={{ top: "8rem" }}
                    >
                      <h3 className="display-2 mb-5">
                        The service we offer is specifically designed to meet
                        your needs.
                      </h3>
                      <p>
                        We at BytezTech aim to provide the best software
                        development services with the touch of our experts who
                        are well trained and know how to convert ideas into
                        working pitch. We are known to create custom solutions
                        depending upon your requirements and unique ideas. We
                        have a team of experts who are always willing to
                        dedicate their effort towards making your dream come
                        into reality and foster your company's reputation and
                        wealth.
                      </p>
                      <p className="mb-7">
                        With choosing our service you will allow your business
                        model to have a reputed client base which will bring
                        more business value to your company.
                      </p>
                      <ModalButton buttonValue={"Share your business need"} />
                    </div>
                    <div className="col-lg-6 ms-auto">
                      <div className="card bg-soft-fuchsia mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/digisolution.svg"
                              className="svg-inject icon-svg icon-svg-md text-fuchsia me-5"
                              alt="illustration of digisolution"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              Customised Digital Solutions for Start-ups
                            </h3>
                            <p>
                              We at BytezTech provide the customised solutions
                              for the startups which create a new perspective
                              and experience to the online audience present.
                            </p>
                            <p>
                              Choose us to get the best solutions to your
                              challenges and develop something more unique and
                              interesting which can help your business to bring
                              business even in the long run.
                            </p>
                            <p className="mb-0">
                              We can create the customisation web solutions for
                              your business which can stand you out from the
                              crowd and can be important for your business’s
                              success.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-aqua mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/automation.svg"
                              className="svg-inject icon-svg icon-svg-md text-aqua me-5"
                              alt="illustration of automation"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              Workflow Automation Systems
                            </h3>
                            <p>
                              Choose our workflow automation system which will
                              allow your business to get the smoothest
                              Streamline operations. With our professional teams
                              of engineers we ensure that the Workflow
                              Automation Systems can bring more success and
                              support to your business.
                            </p>
                            <p>
                              With our experts we make sure that all of the
                              complexities will turn into leading business
                              results and create wealth.
                            </p>
                            <p className="mb-0">
                              A smooth workflow automation system will help your
                              business to ensure that every aspect of the task
                              flows smoothly and with the right pace of speed
                              and precision.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-yellow mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/mvp.svg"
                              className="svg-inject icon-svg icon-svg-md text-yellow me-5"
                              alt="illustration of mvp"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">MVP</h3>
                            <p>
                              The MVP development provided by our MVP will
                              validate your vision and help you to launch faster
                              growth. With us you will be working on your
                              company's vision and future all along as we
                              provide a dedicated team of experts who will give
                              you the valuable digital solutions.
                            </p>
                            <p className="mb-0">
                              You can target your specific audience and bring a
                              precise understanding of your company among the
                              crowd. With us you will choose the growth and
                              success for your business.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-leaf mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/iot.svg"
                              className="svg-inject icon-svg icon-svg-md text-leaf me-5"
                              alt="illustration of iot"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">
                              IoT based development
                            </h3>
                            <p>
                              We at BytezTech provide the best pioneers in IoT
                              development which will be able to connect with the
                              devices and bring more innovative experiences to
                              your business ecosystem. The IoT based development
                              will transform your current faced challengers and
                              convert them into interconnected and smart
                              solutions which will drive more business to your
                              company.
                            </p>
                            <p className="mb-0">
                              The IoT based development will bring more value
                              and develop the technology which will eventually
                              boost your user interface and experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-soft-leaf mb-6">
                        <div className="card-body d-flex flex-row">
                          <div>
                            <img
                              src="./assets/img/icons/custom/chatbot.svg"
                              className="svg-inject icon-svg icon-svg-md text-leaf me-5"
                              alt="illustration of chatbot"
                            />
                          </div>
                          <div>
                            <h3 className="fs-21 mb-2">Chatbot</h3>
                            <p>
                              We at BytezTech have experts who can create great
                              APIs like chatbot which can enhance the user
                              experience but also create your business more
                              responsive and reliable to your customers. With
                              our engaging BytezTech chat bot solutions your
                              company will be availing a user friendly yet a
                              unique chatbot which will transform and aid your
                              business easily.
                            </p>
                            <p className="mb-0">
                              The assistance provided by our chatbots would
                              feels wonderfully as a human which will be able to
                              create satisfying interactions with the clients
                              and boost your business growth.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Tech />
      <Address />
    </>
  );
};

export default Appservice;
