import React from "react";
import { Link } from "react-router-dom";
import {
  isMobileDevice,
  applyMobileImageClass,
  getResponsiveBackgroundImage,
  useMobileDetect,
} from "../components/hooks/deviceUtils.js";
const Footer = () => {
  const isDeviceMobile = isMobileDevice();
  const backgroundImageStyle = {
    backgroundImage: "url('./assets/img/photos/bg3.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  };

  return (
    <footer className="bg-dark text-inverse">
      <div className="container">
        <div className="row">
          <div className="col-xl-11 col-xxl-10 mx-auto">
            <div
              className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400 mt-n50p mb-n5"
              style={backgroundImageStyle}
            >
              <div className="card-body p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                <h3 className="display-6 mb-6 mb-lg-0 pe-lg-15 pe-xxl-18 text-white">
                  We are trusted by over 121+ clients. Join them by using our
                  services and grow your business.
                </h3>
                <Link
                  to="/contact"
                  className="btn btn-sm btn-white rounded mb-0 text-nowrap"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <img
                className="mb-4"
                src="./assets/img/byteztechlogo.png"
                srcSet="./assets/img/byteztechlogo2x.png 2x"
                alt=""
              />
              <p className="mb-4">
                © {new Date().getFullYear()} BytezTech.{" "}
                <br className="d-none d-lg-block" />
                All rights reserved.
              </p>
              <nav className="nav social social-white">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to="https://twitter.com/BytezTechBytez"
                >
                  <i className="fa-brands fa-x-twitter"></i>
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to="https://www.facebook.com/BytezTech"
                >
                  <i className="uil uil-facebook-f"></i>
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to="https://www.linkedin.com/company/byteztech/"
                >
                  <i className="uil uil-linkedin"></i>
                </Link>
              </nav>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Get in Touch</h4>
              <address className="pe-xl-15 pe-xxl-17">
                607-608 BytezTech, Rio Business Hub, Near Yamuna Chowk, Mota
                Varachha, Surat, Gujarat 394101
              </address>
              <a href="mailto:#">Info@byteztech.in</a>
              <br /> +91 6351100121
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Learn More</h4>
              <ul className="list-unstyled  mb-0">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/application-development">Mobile App Service</Link>
                </li>
                <li>
                  <Link to="/web-development">Web Development</Link>
                </li>
                <li>
                  <Link to="/terms-and-condition">Terms of Use</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
              <p className="mb-5">
                Subscribe to our newsletter to get our news & deals delivered to
                you.
              </p>
              <div className="newsletter-wrapper">
                <div>
                  <form className="validate dark-fields" noValidate>
                    <div>
                      <div className="mc-field-group input-group form-floating">
                        <input
                          type="email"
                          name="EMAIL"
                          className="required email form-control"
                          placeholder="Email Address"
                        />
                        <label htmlFor="mce-EMAIL2">Email Address</label>
                        <input
                          type="submit"
                          value="Join"
                          name="subscribe"
                          className="btn btn-primary"
                        />
                      </div>
                      <div className="clear"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
