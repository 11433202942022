import { useEffect, useRef, useCallback } from "react";

const useScrollCue = () => {
  const observerRef = useRef(null);
  const elementsRef = useRef(new Set());

  const initializeElement = useCallback((element) => {
    if (!element) return;

    // Reset initial state for animation
    element.style.opacity = "0";
    element.style.transition = "all 0.8s ease";

    // Set initial transform based on animation type
    const cue = element.getAttribute("data-cues") || "slideInDown";
    switch (cue) {
      case "slideInDown":
        element.style.transform = "translateY(50px)";
        break;
      case "zoomIn":
        element.style.transform = "scale(0.8)";
        break;
      default:
        element.style.transform = "translateY(50px)";
    }
  }, []);

  useEffect(() => {
    // Create observer only once
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const element = entry.target;

          // When the element is in the viewport, apply animations
          if (entry.isIntersecting) {
            const cue = element.getAttribute("data-cues") || "slideInDown";
            const delay = element.getAttribute("data-delay") || "0s";

            // Apply animation styles based on the cue
            requestAnimationFrame(() => {
              element.style.opacity = "1";
              element.style.transform = "translate(0, 0) scale(1)";
              element.style.transitionDelay = delay;
              element.style.transitionProperty = "all";
              element.style.transitionDuration = "0.8s";
              element.style.transitionTimingFunction = "ease-out";
            });

            // Stop observing the element after it has been animated
            observerRef.current.unobserve(element);
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: "0px 0px -50px 0px",
      }
    );

    // Observe all current elements
    elementsRef.current.forEach((el) => {
      initializeElement(el);
      observerRef.current.observe(el);
    });

    // Cleanup when component unmounts
    return () => {
      observerRef.current.disconnect();
    };
  }, [initializeElement]);

  // Ref attachment function for dynamically attaching refs to elements
  const addScrollCueRef = useCallback(
    (el) => {
      if (el && !elementsRef.current.has(el)) {
        elementsRef.current.add(el);

        // Initialize and observe element immediately
        initializeElement(el);
        if (observerRef.current) {
          observerRef.current.observe(el);
        }
      }
      return el;
    },
    [initializeElement]
  );

  return addScrollCueRef;
};

export default useScrollCue;
