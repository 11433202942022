import React from 'react'
import { Link } from 'react-router-dom'

const Pagenotfound = () => {
  return (
    <>
      <section className="wrapper bg-light">
        <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
          <div className="row fourzerofour">
            <div className="col-lg-9 col-xl-8 mx-auto">
              <figure className="mb-10">
                <img
                  className="img-fluid"
                  src="./assets/img/illustrations/404.webp"
                  srcset="./assets/img/illustrations/404@2x.webp 2x"
                  alt="page not found "
                />
              </figure>
            </div>
            <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
              <h1 className="mb-3">Oops! Page Not Found.</h1>
              <p className="lead mb-7 px-md-12 px-lg-5 px-xl-7">
                The page you are looking for is not available or has been moved.
                Try a different page or go to homepage with the button below.
              </p>
              <Link to={"/"} className="btn btn-primary rounded-pill">
                Go to Homepage
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pagenotfound