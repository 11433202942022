import React from "react";

const Tech = () => {
  return (
    <section id="snippet-2" class="wrapper bg-gray">
      <div class="container py-14 py-md-16">
        <div class="card-body">
          <ul class="nav nav-tabs nav-pills">
            <li class="nav-item">
              {" "}
              <a class="nav-link active" data-bs-toggle="tab" href="#tab1-1">
                <img
                  src="assets/img/icons/custom/frontend.svg"
                  class="svg-inject icon-svg solid text-navy mb-0"
                  alt="illustration of frontend"
                />
                <span>Frontend</span>
              </a>{" "}
            </li>
            <li class="nav-item">
              {" "}
              <a class="nav-link" data-bs-toggle="tab" href="#tab1-2">
                <img
                  src="assets/img/icons/custom/backend.svg"
                  class="svg-inject icon-svg solid text-navy mb-0"
                  alt="illustration of backend"
                />
                <span>Backend</span>
              </a>{" "}
            </li>
            <li class="nav-item">
              {" "}
              <a class="nav-link" data-bs-toggle="tab" href="#tab1-3">
                <img
                  src="assets/img/icons/custom/mobileapp.svg"
                  class="svg-inject icon-svg solid text-navy mb-0"
                  alt="illustration of mobileapp"
                />
                <span>Mobile app</span>
              </a>{" "}
            </li>
            <li class="nav-item">
              {" "}
              <a class="nav-link" data-bs-toggle="tab" href="#tab1-4">
                <img
                  src="assets/img/icons/custom/database.svg"
                  class="svg-inject icon-svg solid text-navy mb-0"
                  alt="illustration of database"
                />
                <span>Database</span>
              </a>{" "}
            </li>
            <li class="nav-item">
              {" "}
              <a class="nav-link" data-bs-toggle="tab" href="#tab1-5">
                <img
                  src="assets/img/icons/custom/cloud.svg"
                  class="svg-inject icon-svg solid text-navy mb-0"
                  alt="illustration of cloud"
                />
                <span>Cloud</span>
              </a>{" "}
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="tab1-1">
              <ul class="glyphs glyphs-svg row row-cols-2 row-cols-md-4 row-cols-lg-5 gy-6">
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/react.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of react"
                        />
                      </div>
                      <span>React</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/vuejs.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of vue"
                        />
                      </div>
                      <span>Vue.js</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/nextjs.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of next"
                        />
                      </div>
                      <span>Next.js</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/html5.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of html5"
                        />
                      </div>
                      <span>HTML5</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/css3.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of css3"
                        />
                      </div>
                      <span>CSS3</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="tab-pane fade" id="tab1-2">
              <ul class="glyphs glyphs-svg row row-cols-2 row-cols-md-4 row-cols-lg-5 gy-6">
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/python.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of python"
                        />
                      </div>
                      <span>Python</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/alarm.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of alarm"
                        />
                      </div>
                      <span>Node.js</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/laravel.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of laravel"
                        />
                      </div>
                      <span>Laravel</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/php.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of php"
                        />
                      </div>
                      <span>PHP</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="tab-pane fade" id="tab1-3">
              <ul class="glyphs glyphs-svg row row-cols-2 row-cols-md-4 row-cols-lg-5 gy-6">
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/android.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of android"
                        />
                      </div>
                      <span>Android</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/ios.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of ios"
                        />
                      </div>
                      <span>iOS</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/flutter.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of fluter"
                        />
                      </div>
                      <span>Flutter</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/reactnative.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of reactnative"
                        />
                      </div>
                      <span>React Native</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="tab-pane fade" id="tab1-4">
              <ul class="glyphs glyphs-svg row row-cols-2 row-cols-md-4 row-cols-lg-5 gy-6">
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/mongodb.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of mongodb"
                        />
                      </div>
                      <span>MongoDB</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/mysql.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of mysql"
                        />
                      </div>
                      <span>MySQL</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/sqlite.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of sqlite"
                        />
                      </div>
                      <span>SQLite</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/firebase.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of firebase"
                        />
                      </div>
                      <span>Firebase</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="tab-pane fade" id="tab1-5">
              <ul class="glyphs glyphs-svg row row-cols-2 row-cols-md-4 row-cols-lg-5 gy-6">
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/aws.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of aws"
                        />
                      </div>
                      <span>AWS</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/digitalocean.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of digitalocean"
                        />
                      </div>
                      <span>Digital ocean</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/solid/azure.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of azure"
                        />
                      </div>
                      <span>Azure</span>
                    </div>
                  </div>
                </li>
                <li class="col text-center">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="rounded-xl mb-2">
                        <img
                          src="assets/img/icons/custom/googlecloud.svg"
                          class="svg-inject icon-svg solid text-navy mb-0"
                          alt="illustration of google cloud"
                        />
                      </div>
                      <span>Google Cloud</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tech;
