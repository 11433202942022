import React, { useEffect } from "react";
import Cover from "./Cover";
import Features from "./Features";
import Howitworks from "./Howitworks";
import Ourprojects from "./Ourprojects";
import Stats from "./Stats";
import Reviews from "./Reviews";
import Clients from "./Clients";
import Workinfo from "./Workinfo";
import Journal from "./Journal";
import Address from "./Address";
import SVGInject from "@iconfu/svg-inject/dist/svg-inject.min.js";
import MetaData from "./meta/MetaData";

const Homepage = () => {
  useEffect(() => {
    var theme = {
      init: function () {
        theme.svgInject();
      },
      svgInject: () => {
        SVGInject.setOptions({
          onFail: function (img, svg) {
            img.classList.remove("svg-inject");
          },
        });
        SVGInject(document.querySelectorAll("img.svg-inject"), {
          useCache: true,
        });
      },
    };
    theme.init();
  }, []);
  return (
    <>
      <MetaData />
      <Cover />
      <Features />
      <Howitworks />
      <Ourprojects />
      <Stats />
      <Reviews />
      <Clients />
      <Workinfo bgClass={"bg-light"} />
      <Journal />
      <Address />
    </>
  );
};

export default Homepage;
