import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, A11y, Parallax } from "swiper";

const SwiperSlider = ({
  slides = [],
  spaceBetween = 30,
  slidesPerView = 1,
  pagination = {
    clickable: true,

  },
  autoplay = {
    delay: 3000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
    stopOnLastSlide: true,
  },
  loop = false,
  grabCursor = true,
  allowTouchMove = true,
  keyboard = {
    enabled: true,
    onlyInViewport: false,
  },
  a11y = {
    enabled: true,
  },
  breakpoints = {
    320: { slidesPerView: 1, spaceBetween: 10 },
    480: { slidesPerView: 1, spaceBetween: 20 },
    640: { slidesPerView: 1, spaceBetween: 30 },
  },
  nav = false, // Default is false
  parallax = false,
  containerStyle = {},
}) => {
  return (
    <div className="swiper-container" data-swiper-parallax-bg="50%">
      <Swiper
        modules={[
          Autoplay,
          Pagination,
          A11y,
          Parallax,

          ...(nav ? [Navigation] : []),
        ]} // Include Navigation if nav is true
        parallax={parallax}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        pagination={pagination}
        autoplay={autoplay}
        loop={loop}
        grabCursor={grabCursor}
        allowTouchMove={allowTouchMove}
        keyboard={keyboard}
        breakpoints={breakpoints}
        a11y={a11y}
        navigation={
          nav
            ? {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }
            : false
        } // Enable navigation only if nav is true
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              data-swiper-parallax="-300"
              data-swiper-parallax-opacity="0.7"
              data-swiper-parallax-scale="0.9"
            >
              {slide}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-controls">
        <div className="swiper-pagination"></div>
        {nav && (
          <div className="swiper-navigation">
            <div className="swiper-button swiper-button-prev"></div>
            <div className="swiper-button swiper-button-next"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SwiperSlider;
