import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Address from "./Address";
import ImageOverlay from "./hooks/overlay.js";
import { Helmet } from "react-helmet-async";
const Blog = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Blogs on Technology Trends and Insights | BytezTech</title>
        <meta
          name="description"
          content="Blogs covering the latest technology trends, insights, and innovations. Stay updated with expert articles and resources from the BytezTech team."
        />
        <meta
          name="keywords"
          content="Blogs on Technology Trends and Insights | BytezTech"
        />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/illustrations/3d12.png"
        />
        <meta
          property="og:title"
          content="Blogs on Technology Trends and Insights | BytezTech"
        />
        <meta
          name="og:description"
          content="Blogs covering the latest technology trends, insights, and innovations. Stay updated with expert articles and resources from the BytezTech team."
        />
        <meta property="og:url" content="https://byteztech.com/blogs " />
        <link rel="canonical" href="https://byteztech.com/blogs " />
        <meta
          name="twitter:title"
          content="Blogs on Technology Trends and Insights | BytezTech"
        />
        <meta
          name="twitter:description"
          content="Blogs covering the latest technology trends, insights, and innovations. Stay updated with expert articles and resources from the BytezTech team."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/illustrations/3d12.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Blog",
            image:
              "https://www.byteztech.com/assets/img/illustrations/3d12.png",
            description:
              "Blogs covering the latest technology trends, insights, and innovations. Stay updated with expert articles and resources from the BytezTech team.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.6",
              ratingCount: "128",
            },
          })}
        </script>
      </Helmet>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="grid grid-view projects-masonry shop mb-13">
            <div className="row gx-md-8 gy-10 gy-md-13 isotope">
              <div className="project item col-md-6 col-xl-4">
                <article>
                  <div className="card">
                    <figure className="card-img-top overlay overlay-1 hover-scale">
                      <Link
                        to={
                          "/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT"
                        }
                      >
                        <ImageOverlay
                          imageUrl=" ./assets/img/blogs/iot/Blog-IoT.webp" // Ensure path is correct
                          alt="Illustration of Blog -Iot"
                          className="img-fluid" // Add 'img-fluid' class for responsiveness
                        >
                          <h5 className="from-top mb-0">Read More</h5>{" "}
                          {/* Only one "Read More" text here */}
                        </ImageOverlay>
                      </Link>
                    </figure>

                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to={"#"} className="hover" rel="category">
                            IoT
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link
                            className="link-dark"
                            to={
                              "/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT"
                            }
                          >
                            Simplifying Life: The Marriage of Smart Homes and
                            IoT
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Explore the remarkable transformation of living spaces
                          through IoT. Dive into the trends steering smart home
                          innovation and how they pave the way for a future
                          where convenience and sustainability coexist.
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="post-meta d-flex mb-0">
                        <li className="post-comments">
                          <Link
                            to={
                              "/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT"
                            }
                          >
                            Read More
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              </div>

              <div className="project item col-md-6 col-xl-4">
                <article>
                  <div className="card">
                    <figure className="card-img-top overlay overlay-1 hover-scale">
                      <Link
                        to={
                          "/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming"
                        }
                      >
                        <ImageOverlay
                          imageUrl="./assets/img/blogs/ai-farming/AI-Agriculture.webp" // Ensure path is correct
                          alt="Illustration of AI-agriculture"
                          className="img-fluid" // Add 'img-fluid' class for responsiveness
                        >
                          <h5 className="from-top mb-0">Read More</h5>{" "}
                          {/* This is the only "Read More" */}
                        </ImageOverlay>
                      </Link>
                    </figure>
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to={"#"} className="hover" rel="category">
                            Technology
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link
                            className="link-dark"
                            to={
                              "/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming"
                            }
                          >
                            AI's Role in Modern Agriculture: A New Dawn for
                            Farming
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Delve into the revolutionary role of AI in
                          agriculture. Discover how smart farming is redefining
                          food production, leading to enhanced yield quality and
                          sustainability, ushering a new era in agriculture.
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="post-meta d-flex mb-0">
                        <li className="post-comments">
                          <Link
                            to={
                              "/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming"
                            }
                          >
                            Read More
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              </div>

              <div className="project item col-md-6 col-xl-4">
                <article>
                  <div className="card">
                    <figure className="card-img-top overlay overlay-1 hover-scale">
                      <Link
                        to={
                          "/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership"
                        }
                      >
                        <ImageOverlay
                          imageUrl="./assets/img/blogs/nft/NFT-main.webp" // Ensure path is correct
                          alt="Illustration of NFTs"
                          className="img-fluid" // Add 'img-fluid' class for responsiveness
                        >
                          <h5 className="from-top mb-0">Read More</h5>{" "}
                          {/* Only one "Read More" text here */}
                        </ImageOverlay>
                      </Link>
                    </figure>
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to={"#"} className="hover" rel="category">
                            Technology
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link
                            className="link-dark"
                            to={
                              "/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership"
                            }
                          >
                            NFTs: Changing the Landscape of Art, Assets, and
                            Digital Ownership
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Embark on a journey through the disruptive world of
                          NFTs. Learn how these unique digital assets are
                          reshaping industries, offering a glimpse into the
                          evolving dynamics of ownership and value in the
                          virtual domain.
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <ul className="post-meta d-flex mb-0">
                        <li className="post-comments">
                          <Link
                            to={
                              "/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership"
                            }
                          >
                            Read More
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
};

export default Blog;
