import React, { useEffect, useRef, useState } from "react";
import Address from "../Address";
import ModalButton from "../reactModal/ModalButton";
import { Link } from "react-router-dom";
import useScrollCue from "../hooks/useScrollCue.js";
import { Helmet } from "react-helmet-async";
const Webdev = () => {
  const rellaxElements = useRef([]);
  const rellaxRefs = useRef([]);
  // Call the custom hook
  const addScrollCueRef = useScrollCue()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      rellaxElements.current.forEach((el) => {
        const speed = el.getAttribute("data-rellax-speed") || 1;
        const direction = el.getAttribute("data-rellax-direction") || "normal";

        const offset =
          direction === "reverse" ? -scrollPosition : scrollPosition;
        el.style.transform = `translateY(${offset * speed}px)`;
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !rellaxElements.current.includes(el)) {
      rellaxElements.current.push(el);
    }
  };

  useEffect(() => {
    // Ensure page scroll starts at the top
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);
  
  return (
    <>
      <Helmet>
        <title>
          Web Development Services for Modern Businesses | BytezTech
        </title>
        <meta
          name="description"
          content="Web development solutions designed to enhance your online presence. Create responsive, user-friendly websites with expert services by BytezTech."
        />
        <meta
          name="keywords"
          content="Web Development Services for Modern Businesses | BytezTech"
        />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/illustrations/hire-app-img.png"
        />
        <meta
          property="og:title"
          content="Web Development Services for Modern Businesses | BytezTech"
        />
        <meta
          name="og:description"
          content="Web development solutions designed to enhance your online presence. Create responsive, user-friendly websites with expert services by BytezTech."
        />
        <meta
          property="og:url"
          content="https://byteztech.com/web-development "
        />
        <link
          rel="canonical"
          href="https://byteztech.com/web-development "
        />
        <meta
          name="twitter:title"
          content="Web Development Services for Modern Businesses | BytezTech"
        />
        <meta
          name="twitter:description"
          content="Web development solutions designed to enhance your online presence. Create responsive, user-friendly websites with expert services by BytezTech."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/illustrations/hire-app-img.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Web-development",
            image:
              "https://www.byteztech.com/assets/img/illustrations/hire-app-img.png",
            description:
              "Web development solutions designed to enhance your online presence. Create responsive, user-friendly websites with expert services by BytezTech.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.7",
              ratingCount: "129",
            },
          })}
        </script>
      </Helmet>
      <section className="wrapper bg-light">
        <div className="container pt-8 pt-md-14">
          <div className="row gx-lg-0 gx-xl-8 gy-10 gy-md-13 gy-lg-0 mb-7 mb-md-10 mb-lg-16 align-items-center">
            <div
              className="col-md-8 offset-md-2 col-lg-6 offset-lg-1 position-relative order-lg-2"
              data-cues="zoomIn"
              ref={addScrollCueRef} // Add ref here
            >
              <div
                className="shape bg-dot primary rellax w-17 h-19"
                data-rellax-speed="1"
                style={{ top: "-1.7rem", left: "-1.5rem" }}
                ref={(el) => rellaxRefs.current.push(el)}
              ></div>
              <div
                className="shape rounded bg-soft-primary rellax d-md-block"
                data-rellax-speed="0.5"
                data-rellax-direction="reverse"
                style={{
                  bottom: "-1.8rem",
                  right: "-0.8rem",
                  width: "85%",
                  height: "90%",
                }}
                ref={(el) => rellaxRefs.current.push(el)}
              ></div>
              <figure className="rounded">
                <img
                  src="./assets/img/illustrations/hire-app-img.png"
                  srcSet="./assets/img/illustrations/hire-app-img.png 2x"
                  alt="illustration of hire app "
                />
              </figure>
            </div>
            <div
              className="col-lg-5 mt-lg-n10 text-center text-lg-start"
              ref={addScrollCueRef}
              data-cues="slideInDown"
              data-group="page-title"
              data-delay="0.6s"
            >
              <h1 className="display-1 mb-5">
                Unlock Enterprise Success with Dedicated Web Developers
              </h1>
              <p className="lead lh-sm mb-7 px-md-10 px-lg-0">
                Elevate your enterprise with our dedicated web developers who
                bring unmatched expertise to the table. Our skilled team is
                ready to tackle your most complex projects, delivering tailored
                solutions that align with your business goals. Partner with us
                to transform your digital presence and drive growth.
              </p>
              <div
                className="d-flex justify-content-center justify-content-lg-start"
                ref={addScrollCueRef}
                data-cues="slideInDown"
                data-group="page-title-buttons"
                data-delay="0.9s"
              >
                <span>
                  <ModalButton buttonValue={"Share your business need"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 pt-md-14 pb-md-18">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 mb-md-14 align-items-center">
            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-muted mb-3">
                Let’s Talk
              </h2>
              <h3 className="display-4 mb-3">Hire web developer</h3>
              <p>
                Hire Web Developers From BytezTech: Elevate your online presence
                with our dedicated web developers. Proficient in an array of
                cutting-edge technologies, including MERN, LAMP, MEAN, Python
                (Django & Flask), and PHP (Laravel), our team crafts dynamic
                websites that resonate with your audience. Every project is a
                fusion of our decade-long expertise, technical prowess, and deep
                understanding of industry trends. At BytezTech, we recognize
                that a successful web presence is more than just aesthetics;
                it's about delivering seamless user experiences, scalable
                solutions, and staying ahead in the digital landscape. When you
                partner with us, you're not just hiring developers; you're
                forging a path to digital excellence.
              </p>
              <ModalButton buttonValue={"Share your business need"} />
            </div>
            <div className="col-lg-6">
              <h2 className="fs-15 text-uppercase text-line text-primary mb-7">
                Why hire our developers?
              </h2>
              <div className="d-flex flex-row mb-4">
                <div>
                  <img
                    src="./assets/img/icons/custom/expertise.svg"
                    className="svg-inject icon-svg icon-svg-sm text-primary me-4"
                    alt="illustration of expertise"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Tech Experts</h4>
                  <p className="mb-1">
                    Proven proficiency in the latest web technologies for
                    cutting-edge solutions.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-4">
                <div>
                  <img
                    src="./assets/img/icons/custom/customsol.svg"
                    className="svg-inject icon-svg icon-svg-sm text-green me-4"
                    alt="illustration of customsol"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Custom Solutions</h4>
                  <p className="mb-1">
                    Tailored development to fit your unique enterprise
                    requirements.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-4">
                <div>
                  <img
                    src="./assets/img/icons/custom/trackrecord.svg"
                    className="svg-inject icon-svg icon-svg-sm text-green me-4"
                    alt="illustration of trackrecord"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Track Record</h4>
                  <p className="mb-1">
                    Demonstrated success in delivering impactful projects.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-4">
                <div>
                  <img
                    src="./assets/img/icons/custom/colapproach.svg"
                    className="svg-inject icon-svg icon-svg-sm text-green me-4"
                    alt="illustration of colapproach"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Collaborative Approach</h4>
                  <p className="mb-1">
                    Seamless teamwork and communication for outstanding results.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/longtp.svg"
                    className="svg-inject icon-svg icon-svg-sm text-yellow me-4"
                    alt="illustration of longtp"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Long-Term Partnership</h4>
                  <p className="mb-0">
                    Beyond development, we're committed to your ongoing success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-gray">
        <div className="container py-14 py-md-16">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                Hire Our FullStack Web Developers
              </h2>
              <h3 className="display-7 mb-11 px-xl-11">
                Unleash the power of end-to-end web solutions with BytezTech's
                skilled developers. From front-end magic to back-end robustness,
                our team crafts comprehensive websites that seamlessly integrate
                form and function, delivering engaging user experiences and
                unparalleled performance.
              </h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8">
            <div className="col-md-6 col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/prowess.svg"
                    className="svg-inject icon-svg icon-svg-sm text-aqua me-4"
                    alt="illustration of prowess"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Tech Prowess</h4>
                  <p className="mb-0">
                    Our web developers ace diverse stacks - MERN, LAMP, MEAN,
                    Python (Django & Flask), and PHP (Laravel), guaranteeing
                    versatile solutions for all your web ventures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/desexcell.svg"
                    className="svg-inject icon-svg icon-svg-sm text-yellow me-4"
                    alt="illustration of desexcell"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Design Excellence</h4>
                  <p className="mb-0">
                    Crafting interfaces that captivate, we merge aesthetics with
                    usability for engaging user experiences that leave lasting
                    impressions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/immain.svg"
                    className="svg-inject icon-svg icon-svg-sm text-red me-4"
                    alt="illustration of immain"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Impeccable Maintenance</h4>
                  <p className="mb-0">
                    Our watchful eye ensures continuous website optimization,
                    updates, and rapid bug resolution, sustaining peak
                    performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/roundsupp.svg"
                    className="svg-inject icon-svg icon-svg-sm text-pink me-4"
                    alt="illustration of roundsupp"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Round-the-Clock Support</h4>
                  <p className="mb-0">
                    We're at your service 24/7, promptly addressing any queries
                    or issues, ensuring uninterrupted website function and user
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/agileapp.svg"
                    className="svg-inject icon-svg icon-svg-sm text-green me-4"
                    alt="illustration of agileapp"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Agile Approach</h4>
                  <p className="mb-0">
                    Adapting to market trends, our agile methodologies ensure
                    timely iterations, seamless development, and web solutions
                    primed for success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/comwebsol.svg"
                    className="svg-inject icon-svg icon-svg-sm text-purple me-4"
                    alt="illustration of comwebsol"
                  />
                </div>
                <div>
                  <h4 className="mb-1">Complete Web Solutions</h4>
                  <p className="mb-0">
                    From ideation to launch and post-launch maintenance, our
                    full-stack offerings cover the entire web development
                    spectrum, cementing your digital triumph..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <h2 className="display-4 mb-3 text-center">FAQs</h2>
          <p className="lead text-center mb-10 px-md-16 px-lg-0">
            If you don't see an answer to your question, you can send us an
            email from our contact form.
          </p>
          <div className="row">
            <div className="col-lg-6 mb-0">
              <div id="accordion-1" className="accordion-wrapper">
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-1">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-1"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-1"
                    >
                      Which web development technologies do you specialize in?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-1"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-1"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Our web developers excel in a range of modern
                        technologies, including MERN, LAMP, MEAN, Python (Django
                        & Flask), and PHP (Laravel), ensuring robust and
                        adaptable solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-2">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-2"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-2"
                    >
                      How long does it take to develop a website?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-2"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-2"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        The development timeline varies based on project
                        complexity. After discussing your requirements, we'll
                        provide a more accurate estimate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-3">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-3"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-3"
                    >
                      How do you ensure my website's security?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-3"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-3"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Our developers follow industry best practices,
                        incorporating security features, encryption, and regular
                        vulnerability assessments to safeguard your website.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-4">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-4"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-4"
                    >
                      Will my website be responsive on all devices?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-4"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-4"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Absolutely! We prioritize responsive design, ensuring
                        your website looks and functions flawlessly across
                        devices, from desktops to mobile devices.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-4">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-4"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-4"
                    >
                      Do you provide ongoing maintenance for websites?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-4"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-4"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Yes, we offer continuous maintenance to ensure your
                        website remains up-to-date, optimized, and free from
                        bugs, providing seamless user experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="accordion-2" className="accordion-wrapper">
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-1">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-1"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-1"
                    >
                      How do you handle website design?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-1"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-1"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Our design process focuses on user-centered design
                        principles, creating interfaces that are visually
                        appealing, intuitive, and aligned with your brand
                        identity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-2">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-2"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-2"
                    >
                      Can I see the progress of my website during development?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-2"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-2"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Certainly! We provide regular updates and demos
                        throughout development, keeping you involved in the
                        process and ensuring your vision is being realized.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-3">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-3"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-3"
                    >
                      What post-launch support do you offer?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-3"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-3"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        We provide comprehensive post-launch support, addressing
                        any issues, updates, or enhancements to maintain your
                        website's peak performance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-4">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-4"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-4"
                    >
                      How do you ensure my website's compatibility with
                      different browsers?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-4"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-4"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Our rigorous testing ensures cross-browser
                        compatibility, making sure your website functions
                        seamlessly across all major browsers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-4">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-4"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-4"
                    >
                      How do you optimize website performance for speed?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-4"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-4"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        We implement various techniques like optimizing images,
                        leveraging browser caching, and minifying code to ensure
                        swift load times and smooth interactions for users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-gray">
        <div className="container py-14 py-md-16">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="display-6 mb-9 px-xl-11">
                Unlock unparalleled growth with BytezTech's dedicated mobile
                developer team, your partners in crafting success stories.
              </h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-6 px-xl-5">
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/fixedprice.svg"
                    className="svg-inject icon-svg icon-svg-sm text-purple me-4"
                    alt="illustration of fixedprice"
                  />
                </div>
                <div>
                  <h4>Fixed-Price Model</h4>
                  <p className="mb-2">
                    Achieve clear-cut goals with our predictable budgeting;
                    perfect for well-defined project scopes.
                  </p>
                  <Link to="#" className="more hover">
                    Share your business need
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/hourlybased.svg"
                    className="svg-inject icon-svg icon-svg-sm text-purple me-4"
                    alt="illustration of hourlybased"
                  />
                </div>
                <div>
                  <h4>Hourly Based</h4>
                  <p className="mb-2">
                    Flexibility meets precision; adjust scope and refine
                    priorities, ensuring every hour counts towards your vision.
                  </p>
                  <Link to="#" className="more hover">
                    Share your business need
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <img
                    src="./assets/img/icons/custom/dediteam.svg"
                    className="svg-inject icon-svg icon-svg-sm text-purple me-4"
                    alt="illustration of deditem"
                  />
                </div>
                <div>
                  <h4>Dedicated Team</h4>
                  <p className="mb-2">
                    Forge long-term partnerships with our committed team,
                    tailoring solutions to your evolving needs and ensuring
                    sustained growth.
                  </p>
                  <Link to="#" className="more hover">
                    Share your business need
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
};

export default Webdev;
