export const disableRightClick = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevents the default right-click menu
  };

  document.addEventListener("contextmenu", handleContextMenu);

  return () => {
    document.removeEventListener("contextmenu", handleContextMenu);
  };
};

export const disableF12Key = () => {
  const handleKeyDown = (e) => {
    if (e.key === "F12") {
      e.preventDefault(); // Prevents F12 from opening DevTools
    }
  };

  document.addEventListener("keydown", handleKeyDown);

  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
};
