import React, { useState, useEffect } from "react";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";

const ContactWidgets = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [widgetPositions, setWidgetPositions] = useState({
    whatsapp: { bottom: "20px", position: "fixed" },
    gmail: { bottom: "80px", position: "fixed" },
  });

  const phoneNumber = "916351100121";
  const email = "Info@byteztech.in";

  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(/[-+ ]/g, "")}`;
    window.open(whatsappLink, "_blank");
  };

  const openGmail = () => {
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink, "_blank");
  };

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleWidgetPositions = () => {
      const footer = document.querySelector("footer");
      const whatsappWidget = document.querySelector("#whatsapp-widget");
      const gmailWidget = document.querySelector("#gmail-widget");

      if (footer && whatsappWidget && gmailWidget) {
        const footerRect = footer.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (footerRect.top <= windowHeight) {
          const safeDistance = footerRect.height / 5;

          setWidgetPositions({
            whatsapp: {
              bottom: `${safeDistance}px`,
              position: "fixed",
            },
            gmail: {
              bottom: `${safeDistance + 60}px`, // Offset for second widget
              position: "fixed",
            },
          });
        } else {
          // Default positioning
          setWidgetPositions({
            whatsapp: { bottom: "20px", position: "fixed" },
            gmail: { bottom: "80px", position: "fixed" },
          });
        }
      }
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    window.addEventListener("scroll", handleWidgetPositions);

    return () => {
      window.removeEventListener("resize", checkMobileView);
      window.removeEventListener("scroll", handleWidgetPositions);
    };
  }, []);

  const createWidgetStyle = (backgroundColor, bottom) => ({
    position: "fixed",
    bottom: bottom,
    right: "20px",
    backgroundColor: backgroundColor,
    borderRadius: "50%",
    width: "45px",
    height: "45px",
    display: isMobile ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    zIndex: 1001,
    transition: "bottom 0.3s ease",
  });

  return (
    <>
      <div
        id="whatsapp-widget"
        onClick={openWhatsApp}
        style={createWidgetStyle("#25D366", widgetPositions.whatsapp.bottom)}
      >
        <FaWhatsapp style={{ color: "white", fontSize: "30px" }} />
      </div>
      <div
        id="gmail-widget"
        onClick={openGmail}
        style={createWidgetStyle("#D44638", widgetPositions.gmail.bottom)}
      >
        <FaEnvelope style={{ color: "white", fontSize: "24px" }} />
      </div>
    </>
  );
};

export default ContactWidgets;
