import React, { useState, useEffect, useRef } from "react";
import Headhesive from "headhesive";
import { Link } from "react-router-dom";

const Header = () => {
  const mobileScreen = window.matchMedia("(max-width: 575px)");
  const navbarRef = useRef(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isOffcanvasVisible, setOffcanvasVisible] = useState(false);
  // const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const options = {
      offset: 350,
      offsetSide: "top",
      classes: {
        clone: "navbar-clone fixed",
        stick: "navbar-stick",
        unstick: "navbar-unstick",
      },
      onStick: function () {
        const clonedElem = this.clonedElem;
        if (
          clonedElem.classList.contains("transparent") &&
          clonedElem.classList.contains("navbar-dark")
        ) {
          clonedElem.classList.replace("navbar-dark", "navbar-light");
        }
      },
    };

    const banner = new Headhesive(navbarRef.current, options);

    return () => {
      banner.destroy();
    };
  }, []);

  const toggleDropdown = (dropdownId) => {
    setActiveDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const toggleOffcanvas = () => {
    setOffcanvasVisible((prev) => !prev);
  };

  const closeOffcanvas = () => {
    setOffcanvasVisible(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        isOffcanvasVisible &&
        !e.target.closest(".offcanvas") &&
        !e.target.closest(".hamburger")
      ) {
        closeOffcanvas();
      }
    };

    // MODIFIED: Removed scroll event listener to prevent auto-closing
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOffcanvasVisible]);

  useEffect(() => {
    const clonedNavbar = document.querySelector(".navbar-clone");
    if (clonedNavbar) {
      const hamburger = clonedNavbar.querySelector(".hamburger");
      if (hamburger) {
        hamburger.addEventListener("click", toggleOffcanvas);
      }

      return () => {
        if (hamburger) {
          hamburger.removeEventListener("click", toggleOffcanvas);
        }
      };
    }
  }, [isOffcanvasVisible]);

  // const [sectionsOffset, setSectionsOffset] = useState({
  //   paddingTop: "0px",
  //   marginTop: "0px",
  // });

  // useEffect(() => {
  //   const updateNavbarHeight = () => {
  //     if (navbarRef.current) {
  //       const height = navbarRef.current.offsetHeight;
  //       setNavbarHeight(height);
  //     }
  //   };

  //   updateNavbarHeight();

  //   window.addEventListener("resize", updateNavbarHeight);

  //   return () => {
  //     window.removeEventListener("resize", updateNavbarHeight);
  //   };
  // }, []);

  // useEffect(() => {
  //   setSectionsOffset({
  //     paddingTop: `${navbarHeight}px`,
  //     marginTop: `-${navbarHeight}px`,
  //   });
  // }, [navbarHeight]);

  return (
    <header className="wrapper bg-light">
      <nav ref={navbarRef} className="navbar navbar-expand-lg classic transparent navbar-light">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <Link to={"/"}>
              <img
                src="./assets/img/byteztechlogo.png"
                srcSet="./assets/img/byteztechlogo2x.png 2x"
                alt="byteztech_logo"
                title="byteztech_logo"
              />
            </Link>
          </div>

          {/* Offcanvas Navbar (for mobile) */}
          <div
            className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ${
              isOffcanvasVisible ? "show" : ""
            }`}
          >
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">BytezTech</h3>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={toggleOffcanvas}
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                {/* About Link */}
                <li className="nav-item">
                  <Link className="nav-link" to={"/about"}>
                    About
                  </Link>
                </li>

                {/* Service Dropdown */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("serviceDropdown")}
                  >
                    Service
                  </a>
                  <div
                    className={`dropdown-menu dropdown-lg ${
                      activeDropdown === "serviceDropdown" ? "show" : ""
                    }`}
                  >
                    <div className="dropdown-lg-content">
                      {/* Mobile App Solution */}
                      <div>
                        <h6 className="dropdown-header">Mobile App Solution</h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              Android app
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              iOS app
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              Flutter app
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              React Native app
                            </Link>
                          </li>
                        </ul>
                      </div>
                      {/* Web Solution */}
                      <div>
                        <h6 className="dropdown-header">Web Solution</h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              MERN stack
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              Python development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              Laravel development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              Magento website
                            </Link>
                          </li>
                        </ul>
                      </div>
                      {/* Custom Solution */}
                      <div>
                        <h6 className="dropdown-header">Custom Solution</h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              SaaS development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              IoT based solution
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              Chatbot development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              Live streaming solution
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

                {/* Hire Team Dropdown */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("hireTeamDropdown")}
                  >
                    Hire Team
                  </Link>
                  <div
                    className={`dropdown-menu dropdown-lg ${
                      activeDropdown === "hireTeamDropdown" ? "show" : ""
                    }`}
                  >
                    <div className="dropdown-lg-content">
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/application-development"}>
                              Mobile App Developer
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-development"}>
                              Web Developer
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

                {/* Career, Case Studies, Blogs Links */}
                <li className="nav-item">
                  <Link className="nav-link" to={"/career"}>
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/casestudies"}>
                    Case Studies
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/blogs"}>
                    Blog
                  </Link>
                </li>

                {/* Contact Link (for mobile screen) */}
                {mobileScreen.matches && (
                  <li className="nav-item">
                    <Link className="nav-link" to={"/contact"}>
                      Contact
                    </Link>
                  </li>
                )}
              </ul>

              {/* Offcanvas Footer (for mobile) */}
              <div className="offcanvas-footer d-lg-none">
                <div>
                  <Link href="mailto:info@byteztech.in" className="link-inverse">
                    info@byteztech.in
                  </Link>
                  <br /> +91 635 1100 121
                  <nav className="nav social social-white mt-4">
                    <Link href="#">
                      <i className="uil uil-twitter"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-facebook-f"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-dribbble"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-instagram"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-youtube"></i>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          {/* Navbar other for Desktop */}
          <div className="navbar-other ms-lg-4">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              {/* Desktop Contact Button */}
              <li className="nav-item d-none d-md-block">
                <Link to={"/contact"} className="btn btn-sm btn-primary rounded">
                  Contact
                </Link>
              </li>
              {/* Mobile Hamburger Menu */}
              <li className="nav-item d-lg-none">
                <button className="hamburger offcanvas-nav-btn" onClick={toggleOffcanvas}>
                  <span></span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
