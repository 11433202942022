import React, { useEffect, useRef, useState } from "react";

const Pageprogress = () => {
  const progressWrapRef = useRef(null);
  const pathRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check screen width and set mobile state
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    // Initial check
    checkScreenWidth();

    // Add resize listener
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup resize listener
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    // Only run if not mobile
    if (isMobile) return;

    const progressWrap = progressWrapRef.current;
    const path = pathRef.current;

    if (progressWrap && path) {
      const pathLength = path.getTotalLength();

      // Initialize the progress circle
      path.style.transition = path.style.WebkitTransition = "none";
      path.style.strokeDasharray = `${pathLength} ${pathLength}`;
      path.style.strokeDashoffset = pathLength;

      // Force reflow
      path.getBoundingClientRect();

      path.style.transition = path.style.WebkitTransition =
        "stroke-dashoffset 10ms linear";

      const handleScroll = () => {
        const scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        const scrollHeight =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
        const progress = pathLength - (scrollTop * pathLength) / scrollHeight;
        path.style.strokeDashoffset = progress;

        if (scrollTop >= 50) {
          progressWrap.classList.add("active-progress");
        } else {
          progressWrap.classList.remove("active-progress");
        }
      };

      const handleClick = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      };

      window.addEventListener("scroll", handleScroll);
      progressWrap.addEventListener("click", handleClick);

      // Cleanup event listeners
      return () => {
        window.removeEventListener("scroll", handleScroll);
        progressWrap.removeEventListener("click", handleClick);
      };
    }
  }, [isMobile]);

  // Don't render anything if it's a mobile screen
  if (isMobile) return null;

  return (
    <div className="progress-wrap" ref={progressWrapRef}>
      <svg
        className="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" ref={pathRef} />
      </svg>
    </div>
  );
};

export default Pageprogress;
