import React, { useEffect, useRef, useState } from "react";
import Hiwabout from "./Hiwabout";
import Address from "./Address";
import { Helmet } from "react-helmet-async";
import { Waypoint } from "react-waypoint"; // Importing react-waypoint for visibility detection
import counterUp from "counterup2"; // Importing counterUp library

const About = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  });

  // for the counter thing
  const counterRef1 = useRef(null); // Ref for the first counter
  const counterRef2 = useRef(null); // Ref for the second counter
  const [hasAnimated, setHasAnimated] = useState(false); // State to track if the animation has already run

  useEffect(() => {
    // Initialize counterUp functionality when the component mounts and only once
    if (hasAnimated) {
      if (counterRef1.current) {
        // console.log('counterRef1:', counterRef1.current); // Debugging line to check if the ref is correctly set
        counterUp(counterRef1.current, { duration: 1000, delay: 50 });
      }
      if (counterRef2.current) {
        //console.log('counterRef2:', counterRef2.current); // Debugging line to check if the ref is correctly set
        counterUp(counterRef2.current, { duration: 1000, delay: 50 });
      }
    }
  }, [hasAnimated]); // The effect runs when `hasAnimated` changes

  // Function to handle waypoint activation for counter animation
  const handleCounterAnimation = () => {
    if (!hasAnimated) {
      setHasAnimated(true); // Set state to trigger animation
    }
  };
  return (
    <>
      <Helmet>
        <title>About BytezTech | Innovative Technology Solutions</title>
        <meta
          name="description"
          content="Discover everything about BytezTech, a leader in innovative technology solutions. Learn how we deliver cutting-edge services to empower businesses globally."
        />
        <meta
          name="keywords"
          content="About BytezTech | Innovative Technology Solutions"
        />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/illustrations/about-topleft.png"
        />
        <meta
          property="og:title"
          content="About BytezTech | Innovative Technology Solutions"
        />
        <meta
          name="og:description"
          content="Discover everything about BytezTech, a leader in innovative technology solutions. Learn how we deliver cutting-edge services to empower businesses globally."
        />
        <meta property="og:url" content="https://byteztech.com/about " />
        <link rel="canonical" href="https://byteztech.com/about " />
        <meta
          name="twitter:title"
          content="About BytezTech | Innovative Technology Solutions"
        />
        <meta
          name="twitter:description"
          content="Discover everything about BytezTech, a leader in innovative technology solutions. Learn how we deliver cutting-edge services to empower businesses globally."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/illustrations/about-topleft.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "About",
            image:
              "https://www.byteztech.com/assets/img/illustrations/about-topleft.png",
            description:
              "Discover everything about BytezTech, a leader in innovative technology solutions. Learn how we deliver cutting-edge services to empower businesses globally.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.4",
              ratingCount: "124",
            },
          })}
        </script>
      </Helmet>
      <section className="wrapper">
        <div className="container py-14 py-md-16">
          <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center mb-10 mb-md-12">
            <div className="col-lg-6">
              <div className="row gx-md-5 gy-5">
                <div className="col-md-6">
                  <figure className="rounded">
                    <img
                      src="./assets/img/illustrations/about-topleft.png"
                      srcSet="./assets/img/illustrations/about-topleft.png 2x"
                      alt="Visual depiction of BytezTech's team collaboration"
                    />
                  </figure>
                </div>
                <div className="col-md-6 align-self-end">
                  <figure className="rounded">
                    <img
                      src="./assets/img/illustrations/about-topright.png"
                      srcSet="./assets/img/illustrations/about-topright.png 2x"
                      alt="Graphic showcasing BytezTech's technological advancements"
                    />
                  </figure>
                </div>
                <div className="col-12">
                  <figure className="rounded mx-md-5">
                    <img
                      src="./assets/img/illustrations/about-bottom.png"
                      srcSet="./assets/img/illustrations/about-bottom.png 2x"
                      alt="visual representation of BytezTech's team spirit and collaboration"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-primary mb-3">
                Who Are We?
              </h2>
              <div>
                <h1 className="display-3 mb-5">
                  Company driven by creative strategies to propel our clients'
                  goals and boost their revenue.
                </h1>
                <p className="mb-6">
                  At the heart of our approach lies a singular focus: our
                  clients and their success. Leveraging innovative strategies,
                  we tailor solutions to align seamlessly with your unique
                  goals. Every project undertaken is a commitment to elevate
                  your brand, ensuring measurable growth. Our expertise
                  translates into real-world revenue boosts, positioning you at
                  the forefront of your industry. Partner with us, and let's
                  co-create a legacy of achievement.
                </p>
              </div>
              <div className="row align-items-center counter-wrapper gy-6">
                <div className="col-md-6">
                  {/* Waypoint for the first counter */}
                  <Waypoint onEnter={handleCounterAnimation} topOffset="50%" />

                  <h3 className="counter" ref={counterRef1}>
                    95.7<span>%</span>
                  </h3>
                  <p className="mb-0">Customer Satisfaction</p>
                </div>
                <div className="col-md-6">
                  <Waypoint onEnter={handleCounterAnimation} topOffset="50%" />

                  <h3 className="counter" ref={counterRef2}>
                    5<span>x</span>
                  </h3>
                  <p className="mb-0">Revenue Growth</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-6 mb-7 mb-md-9">
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-block pe-none btn-soft-primary me-4 w-10 h-10">
                    {" "}
                    <span className="number fs-18">1</span>{" "}
                  </div>
                </div>
                <div>
                  <h3>Our Vision</h3>
                  <p className="mb-2">
                    To fuel digital change, we empower businesses to flourish,
                    innovate, and redefine success. Imagine a future where
                    technology is a dynamic force in the hands of visionaries,
                    shaping a brilliantly transformed tomorrow.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-block pe-none btn-soft-primary me-4 w-10 h-10">
                    {" "}
                    <span className="number fs-18">2</span>{" "}
                  </div>
                </div>
                <div>
                  <h3>Our Mission</h3>
                  <p className="mb-2">
                    To connect your innovative ideas with tangible outcomes, we
                    propel your digital transformation. Our focus is on
                    developing solutions that align with market needs, fuel
                    revenue growth, leave a lasting impression, and redefine
                    your industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-block pe-none btn-soft-primary me-4 w-10 h-10">
                    {" "}
                    <span className="number fs-18">3</span>{" "}
                  </div>
                </div>
                <div>
                  <h3>Our Values</h3>
                  <p className="mb-2">
                    Integrity and authenticity guide our every step, ensuring
                    excellence and commitment in all our endeavors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Hiwabout />
      <Address />
    </>
  );
};

export default About;
