import { useState } from "react";

const ImageOverlay = ({ 
    imageUrl, 
    children, 
    overlayColor = 'rgba(0, 0, 0, 0.2)', 
    hoverColor = 'rgba(0, 0, 0, 0.4)' 
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const containerStyle = {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',  // Image zoom on hover
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: isHovered ? hoverColor : overlayColor,
    opacity: isHovered ? 1 : 0,
    transition: 'opacity 0.3s ease',
    display: 'flex',
    justifyContent: 'center',  // Horizontally center the text
    alignItems: 'center',      // Vertically center the text
    zIndex: 1,
  };

  const textStyle = {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '1.5px',
    opacity: isHovered ? 1 : 0,
    transform: isHovered 
      ? 'translateY(10px)'  // Move the text slightly down on hover
      : 'translateY(10px)',  // Keep it slightly down even when not hovered
    transition: 'all 0.4s ease',
    padding: '10px 15px',
    display: 'block',  // Changed from inline-block to block
    whiteSpace: 'nowrap', // Prevent text from wrapping
    width: '100%',  // Ensures the text occupies full width to center properly
    boxSizing: 'border-box', // Ensures padding doesn't mess with the centering
  };

  return (
    <div
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={imageUrl}
        alt="Overlay"
        style={imageStyle}
      />
      <div style={overlayStyle}>
        <div style={textStyle}>
          {children} {/* "See More" or any other text will be placed here */}
        </div>
      </div>
    </div>
  );
};

export default ImageOverlay;
