import React from "react";
import { Link } from "react-router-dom";
import ImageOverlay from "./hooks/overlay.js";
import SwiperSlider from "./hooks/swiperslider.js";

const Journal = () => {
  const blogSlidesData = [
    {
      img: "./assets/img/blogs/iot/Blog-IoT.webp",
      alt: "Blog-IoT logo",
      category: "IoT",
      link: "/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT",
      title: "Simplifying Life: The Marriage of Smart Homes and IoT",
      date: "14 Aug 2023",
    },
    {
      img: "./assets/img/blogs/ai-farming/AI-Agriculture.webp",
      alt: "AI-agriculture logo",
      category: "Technology",
      link: "/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming",
      title: "AI's Role in Modern Agriculture: A New Dawn for Farming",
      date: "25 Aug 2023",
    },
    {
      img: "./assets/img/blogs/nft/NFT-main.webp",
      alt: "NFT-main logo",
      category: "Technology",
      link: "/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership",
      title:
        "NFTs: Changing the Landscape of Art, Assets, and Digital Ownership",
      date: "04 Sep 2023",
    },
  ];

  return (
    <section className="wrapper bg-gray">
      <div className="container py-14 py-md-16 ">
        <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0  ">
          <div className="col-lg-4 mt-lg-2 ">
            <h2 className="display-4 mb-3">Our Journal</h2>
            <p className="lead fs-lg mb-6 pe-xxl-5">
              Here are the latest news from our blog that got the most
              attention.
            </p>
            <Link to="/blogs" className="btn btn-sm btn-soft-primary rounded">
              View All Blogs
            </Link>
          </div>
          <div className="col-lg-8">
            <SwiperSlider
              slides={blogSlidesData.map((slide, index) => (
                <div className="swiper-slide pb-8" key={index}>
                  <article>
                    <figure className="overlay overlay-1 hover-scale rounded mb-5">
                      <Link to={slide.link}>
                        <ImageOverlay imageUrl={slide.img} alt={slide.alt}>
                          <h5 className="from-top mb-0">Read More</h5>
                        </ImageOverlay>
                      </Link>
                    </figure>
                    <div className="post-header">
                      <div className="post-category text-line">
                        <Link to="#" className="hover" rel="category">
                          {slide.category}
                        </Link>
                      </div>
                      <h2 className="post-title h3 mt-1 mb-3">
                        <Link className="link-dark" to={slide.link}>
                          {slide.title}
                        </Link>
                      </h2>
                    </div>
                    <div className="post-footer">
                      <ul className="post-meta mb-0">
                        <li className="post-date">
                          <i className="uil uil-calendar-alt"></i>
                          <span>{slide.date}</span>
                        </li>
                        <li className="post-comments">
                          <Link to="#">
                            <i className="uil uil-comment"></i>0
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </article>
                </div>
              ))}
              spaceBetween={30}
              slidesPerView={2}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              loop={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
              }}
              className="blog grid-view mb-6 "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Journal;
