import React, { useState } from "react";
import SwiperSlider from "./hooks/swiperslider.js";

const Ourprojects = ({ showHeadings = true }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const Ourdata = [
    {
      web: "./assets/img/Mobileandweb/web/portfolio-1.webp",
      mobile: "./assets/img/Mobileandweb/mobile/portfolio-1.webp",
      title: "Seaqua",
      category: "Mobile App",
    },
    {
      web: "./assets/img/Mobileandweb/web/portfolio-2.webp",
      mobile: "./assets/img/Mobileandweb/mobile/portfolio-2.webp",
      title: "Amazing Car",
      category: "Web Development",
    },
    {
      web: "./assets/img/Mobileandweb/web/portfolio-3.webp",
      mobile: "./assets/img/Mobileandweb/mobile/portfolio-3.webp",
      title: "NIION",
      category: "Mobile App",
    },
    {
      web: "./assets/img/Mobileandweb/web/portfolio-4.webp",
      mobile: "./assets/img/Mobileandweb/mobile/portfolio-4.webp",
      title: "The Aatmanirbhar Bharat",
      category: "Web Development",
    },
  ];
  return (
    <section className="wrapper bg-light">
      <div className="overflow-hidden">
        <div className="container pt-12 pt-lg-7 pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
              {showHeadings && (
                <>
                  <h2 className="fs-16 text-uppercase text-primary mb-3">Latest Projects</h2>
                  <h3 className="display-3 mb-10">
                    Explore Our Premium Mobile & Web App Portfolios: Excellence in Every Delivery.
                  </h3>
                </>
              )}
            </div>
          </div>
          <div
            className="swiper-container swiper-height grid-view nav-bottom nav-color mb-14 "
            style={{ height: "100vh" }}
          >
            <SwiperSlider
              onClick={(e) => {
                e.preventDefault();
                return;
              }}
              slides={Ourdata.map((slide, index) => (
                <div className="swiper-slide" key={index}>
                  <figure className="rounded mb-7" style={{ height: "100%", overflow: "hidden" }}>
                    <a>
                      <img
                        src={isMobile ? slide.mobile : slide.web} // Dynamically use mobile or web image
                        alt={`projects portfolio - ${slide.title}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover", // Ensure the image covers the container
                        }}
                      />
                    </a>
                  </figure>
                  <div className="project-details d-flex justify-content-center flex-column">
                    <div className="post-header">
                      <h2 className="post-title h3">
                        <a className="link-dark">{slide.title}</a>
                      </h2>
                      <div className="post-category text-ash">{slide.category}</div>
                    </div>
                  </div>
                </div>
              ))}
              pagination={false} // Remove dots
              navigation={true} // Add navigation buttons
              loop={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              spaceBetween={30} // Remove space between slides for full-width effect
              slidesPerView={1} // Default for mobile
              breakpoints={{
                320: { slidesPerView: 1 }, // Mobile view
                768: { slidesPerView: 2 }, // Tablet and web view
              }}
              nav={true} // Ensure navigation is enabled
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ourprojects;
