import React from 'react'
import Whoweare from './career/Whoweare'
import Jobs from './career/Jobs'
import Perks from './career/Perks'
import { Helmet } from 'react-helmet-async'
const Career = () => {
  return (
    <>    
         <Helmet>
               <title>Career Opportunities in Technology | BytezTech</title>
               <meta
                 name="description"
                 content="Career opportunities that drive innovation and growth. Join BytezTech to advance your professional journey in a dynamic and tech-driven environment."
               />
               <meta
                 name="keywords"
                 content="Career Opportunities in Technology | BytezTech"
               />
               <meta
                 property="og:image"
                 content="https://www.byteztech.com/assets/img/illustrations/Career.png"
               />
               <meta
                 property="og:title"
                 content="Career Opportunities in Technology | BytezTech"
               />
               <meta
                 name="og:description"
                 content="Career opportunities that drive innovation and growth. Join BytezTech to advance your professional journey in a dynamic and tech-driven environment."
               />
               <meta property="og:url" content="https://byteztech.com/career " />
               <link rel="canonical" href="https://byteztech.com/career " />
               <meta
                 name="twitter:title"
                 content="Career Opportunities in Technology | BytezTech"
               />
               <meta
                 name="twitter:description"
                 content="Career opportunities that drive innovation and growth. Join BytezTech to advance your professional journey in a dynamic and tech-driven environment."
               />
               <meta
                 name="twitter:image"
                 content="https://www.byteztech.com/assets/img/illustrations/Career.png"
               />
               <script type="application/ld+json">
                 {JSON.stringify({
                   "@context": "https://schema.org/",
                   "@type": "Product",
                   name: "Career",
                   image:
                     "https://www.byteztech.com/assets/img/illustrations/Career.png",
                   description:
                     "Career opportunities that drive innovation and growth. Join BytezTech to advance your professional journey in a dynamic and tech-driven environment.",
                   brand: {
                     "@type": "Brand",
                     name: "BytezTech",
                   },
                   aggregateRating: {
                     "@type": "AggregateRating",
                     ratingValue: "4.6",
                     ratingCount: "128",
                   },
                 })}
               </script>
             </Helmet>
      <Whoweare />
      <Jobs />
      <Perks />
    </>
  );
}

export default Career