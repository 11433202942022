import React, { useEffect, useState } from "react";
import devtoolsDetect from "devtools-detect";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import SVGInject from "@iconfu/svg-inject/dist/svg-inject.min.js";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Pageprogress from "./components/Pageprogress";
import { disableRightClick, disableF12Key } from "./utils";

function useDevToolsStatus() {
  const navigate = useNavigate();
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtoolsDetect.isOpen);

  useEffect(() => {
    const handleChange = (event) => {
      setIsDevToolsOpen(event.detail.isOpen);
    };

    if (devtoolsDetect.isOpen) {
      navigate("/Pagenotfound");
    }

    window.addEventListener("devtoolschange", handleChange);
    return () => {
      window.removeEventListener("devtoolschange", handleChange);
    };
  }, []);

  return isDevToolsOpen;
}

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isDevToolsOpen = useDevToolsStatus();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var theme = {
      init: function () {
        theme.svgInject();
      },
      svgInject: () => {
        SVGInject.setOptions({
          onFail: function (img, svg) {
            img.classList.remove("svg-inject");
          },
        });
        SVGInject(document.querySelectorAll("img.svg-inject"), {
          useCache: true,
        });
      },
    };
    theme.init();
  }, [location.pathname]);

  useEffect(() => {
    const cleanupRightClick = disableRightClick();
    const cleanupF12Key = disableF12Key();

    return () => {
      cleanupRightClick();
      cleanupF12Key();
    };
  }, [location.pathname]);

  useEffect(() => {
    const isPageCached = window.performance && window.performance.navigation.type === 2;
    const isPageNotFound = location.pathname === "/Pagenotfound";

    if (isDevToolsOpen && !isPageNotFound && !isPageCached) {
      navigate("/Pagenotfound");
    }
  }, [isDevToolsOpen, location.pathname, navigate]);

  return (
    <>
      <div className="content-wrapper">
        <Header />
        <AppRoutes />
      </div>
      <Footer />
      <Pageprogress />
    </>
  );
}

export default App;
