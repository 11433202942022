import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const ReactModal = (props) => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    businessEmail: "",
    contactNo: "",
    selectedService: "Select the service",
    budget: "",
    projectDetails: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error("reCAPTCHA not completed");
      return;
    }

    axios
      .post(
        "https://byteztech.com/api/submit",
        {
          ...formData,
          recaptchaValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setFormData({
          fullName: "",
          businessEmail: "",
          contactNo: "",
          selectedService: "Select the service",
          budget: "",
          projectDetails: "",
        });
        setRecaptchaValue(null);
        props.onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Get a Free Quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="fullName">
            <Form.Label>Full Name *</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              placeholder="Your Full Name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="businessEmail">
            <Form.Label>Business Email *</Form.Label>
            <Form.Control
              type="email"
              name="businessEmail"
              placeholder="Your Email"
              value={formData.businessEmail}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="contactNo">
            <Form.Label>Contact No with Country code</Form.Label>
            <Form.Control
              type="tel"
              name="contactNo"
              placeholder="Your Contact"
              value={formData.contactNo}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="selectedService">
            <Form.Label>Service you are looking for *</Form.Label>
            <Form.Control
              as="select"
              name="selectedService"
              value={formData.selectedService}
              onChange={handleChange}
              required
            >
              <option value="Select the service">Select the service</option>
              <option value="Mobile app service">Mobile app service</option>
              <option value="Web development service">
                Web development service
              </option>
              <option value="UI/UX Designing">UI/UX Designing</option>
              <option value="Free consultation">Free consultation</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="budget">
            <Form.Label>Your approx budget in $ *</Form.Label>
            <Form.Control
              type="number"
              name="budget"
              placeholder="Your approx budget in $"
              value={formData.budget}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="projectDetails" className="mb-3">
            <Form.Label>Detail of project *</Form.Label>
            <Form.Control
              as="textarea"
              name="projectDetails"
              value={formData.projectDetails}
              onChange={handleChange}
              rows="4"
              required
            />
          </Form.Group>
          <div className="mb-3 text-center">
            <ReCAPTCHA
              sitekey="6Lc7JyUoAAAAAFzRHSzWyxCBsMb8EapJwRVNg5gG"
              onChange={(value) => setRecaptchaValue(value)}
            />
            {!recaptchaValue ? (
              <h6 style={{ color: "red", fontSize: 12 }}>
                ! reCAPTCHA not completed
              </h6>
            ) : null}
          </div>
          <div className="mb-3 text-center">
            <Button className="btn btn-primary" type="submit">
              Get a free quote
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ReactModal;
