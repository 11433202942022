import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useScrollCue from "./hooks/useScrollCue";
import Ourprojects from "./Ourprojects";
import { Link } from "react-router-dom";
import axios from "axios";
import WorkInfo from "./Workinfo";
import { Helmet } from "react-helmet-async";
import SwiperSlider from "./hooks/swiperslider";

const slidesData = [
  {
    content:
      "“Choosing BytezTech was the best decision we made. Their personalized approach, attention to detail, and seamless integration saved us time and resources, resulting in substantial cost savings.”",
    img: "./assets/img/avatars/Kshitij_Mall.webp",
    name: "Kshitij Mall",
    position: "Founder - The Aatmanirbhar Bharat",
  },
  {
    content:
      "“BytezTech transformed our business with its cutting-edge software solution. We experienced a 30% increase in productivity and a significant boost in customer satisfaction.”",
    img: "./assets/img/avatars/Erik.webp",
    name: "Erik Voges",
    position: "CEO - Asset Control",
  },
];

const MobileAndWeb = () => {
  const addScrollCueRef = useScrollCue();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setSubmitted(false);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      console.error("reCAPTCHA not completed");
      return;
    }
    const newFormData = {
      fullName: `${formData.firstName} ${formData.lastName}`,
      businessEmail: formData.email,
      projectDetails: formData.message,
    };

    axios
      .post(
        "https://byteztech.com/api/submit",
        {
          ...newFormData,
          recaptchaValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
        setRecaptchaValue(null);
        setSubmitted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Mobile and Web Development Services | BytezTech</title>
        <meta
          name="description"
          content="Looking for top-notch mobile and web development? BytezTech offers expert solutions in mobile app development and custom web design to help your business grow."
        />
        <meta
          name="keywords"
          content="Mobile and Web Development Services | BytezTech"
        />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/Amazing-car/Amazing%20car2.png"
        />
        <meta
          property="og:title"
          content="Mobile and Web Development Services | BytezTech"
        />
        <meta
          name="og:description"
          content="Looking for top-notch mobile and web development? BytezTech offers expert solutions in mobile app development and custom web design to help your business grow."
        />
        <meta
          property="og:url"
          content="Mobile and Web Development Services | BytezTech"
        />
        <link
          rel="canonical"
          href="Mobile and Web Development Services | BytezTech"
        />
        <meta
          name="twitter:title"
          content="Mobile and Web Development Services | BytezTech"
        />
        <meta
          name="twitter:description"
          content="Looking for top-notch mobile and web development? BytezTech offers expert solutions in mobile app development and custom web design to help your business grow."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/Amazing-car/Amazing%20car2.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Mobile and web development",
            image:
              "https://www.byteztech.com/assets/img/Amazing-car/Amazing%20car2.png",
            description:
              "Looking for top-notch mobile and web development? BytezTech offers expert solutions in mobile app development and custom web design to help your business grow.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              ratingCount: "125",
            },
          })}
        </script>
      </Helmet>
      {/* Cover Section */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pt-md-14 pb-14 pb-md-14">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 align-items-center">
            <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-7 text-center text-lg-start">
              <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">
                What Our Specialties Are:
              </h1>
              <div className="d-flex flex-wrap justify-content-center gap-3 mb-5">
                {[
                  {
                    src: "./assets/img/icons/custom/appdeveloping.svg",
                    text: "App Development",
                    textColor: "text-green",
                  },
                  {
                    src: "./assets/img/icons/custom/webdevloping.svg",
                    text: "Web Development",
                    textColor: "text-green",
                  },
                  {
                    src: "./assets/img/icons/custom/customsol.svg",
                    text: "Custom IoT Solutions",
                    textColor: "text-red",
                  },
                  {
                    src: "./assets/img/icons/custom/ocr-devlopment.PNG",
                    text: "OCR Development",
                    textColor: "text-red",
                  },
                ].map((specialty, index) => (
                  <span
                    key={index}
                    className="badge bg-white text-black p-3 d-flex align-items-center"
                    style={{
                      flex: "1 1 calc(50% - 1rem)",
                      minWidth: "200px",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      src={specialty.src}
                      alt={specialty.text}
                      className={`svg-inject icon-svg icon-svg-md ${specialty.textColor} me-4`}
                      style={{ width: "45px", height: "45px" }}
                    />
                    {specialty.text}
                  </span>
                ))}
              </div>
            </div>
            {/* Right side - Contact Form */}
            <div className="col-lg-5">
              <form
                className="contact-form needs-validation"
                onSubmit={handleSubmit}
                noValidate
                style={{ maxWidth: "500px", margin: "0 auto" }}
              >
                <div className="row gx-4">
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_name"
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="form_name">First Name *</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_lastname"
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last name"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="form_lastname">Last Name *</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="jane.doe@example.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="form_email">Email *</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating mb-4">
                      <textarea
                        id="form_message"
                        name="message"
                        className="form-control"
                        placeholder="Your message"
                        style={{ height: "100px" }}
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                      <label htmlFor="form_message">Message *</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-6 text-center">
                  <ReCAPTCHA
                    sitekey="6Lc7JyUoAAAAAFzRHSzWyxCBsMb8EapJwRVNg5gG"
                    onChange={(value) => setRecaptchaValue(value)}
                  />
                  {!recaptchaValue ? (
                    <h6
                      className="mt-4 mx-1 text-start"
                      style={{ color: "red", fontSize: 12 }}
                    >
                      ! reCAPTCHA not completed
                    </h6>
                  ) : null}
                </div>
                {submitted && (
                  <div>
                    <h6
                      className="mt-4 mx-1 text-start"
                      style={{ color: "green", fontSize: 12 }}
                    >
                      Submitted Successfully
                    </h6>
                  </div>
                )}
                <button type="submit" className="btn btn-primary rounded">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Trusted by Clients Section */}
      <section className="wrapper bg-light">
        <div className="container py-6 pt-lg-4 pb-md-6">
          <h2
            className="fs-18 text-uppercase text-muted text-center mb-12 mt-8"
            style={{ fontSize: "1.5rem" }} // Increase font size for larger text
          >
            Our Featured Clients
          </h2>
          <div className="px-lg-5 mb-8 mb-md-12">
            <div className="row gx-0 gx-md-8 gx-xl-12 gy-8 align-items-center">
              {[
                "Aatmanirbhar.webp",
                "Asset Logo.webp",
                "Devour now.webp",
                "mir512_2.webp",
                "NIION.webp",
                "seaqualogo.webp",
                "Stonfixit.webp",
                "Vgro.webp",
              ].map((logo, index) => (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center"
                >
                  <figure
                    className="px-3 px-md-0 px-xxl-2 text-center"
                    data-cue="fadeIn"
                    ref={addScrollCueRef}
                    data-delay={`0.${index + 1}s`}
                  >
                    <img
                      src={`./assets/img/clientslogo/${logo}`}
                      alt={`Client Logo ${index + 1}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "100px",
                        width: "auto",
                        height: "auto",
                        objectFit: "contain",
                      }}
                      className="img-fluid"
                    />
                  </figure>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/*compony facts */}

      <WorkInfo bgClass={"bg-soft-primary"} />

      {/* Slider of or projects */}
      <section className="wrapper bg-light">
        <div className="container pt-10 pb-10">
          <h2 className="display-4 mb-3 text-center">
            Some of our recent projects
          </h2>
          <p className="lead fs-lg mb-8 text-center">
            We make the difference your customer can feel. We fill creativity
            with outstanding design and the latest technology. We Build Great
            Products.
          </p>

          {/* Insert the Ourprojects component here */}

          <Ourprojects showHeadings={false} />
        </div>
      </section>

      {/*How We Do It*/}

      <section className="wrapper bg-light">
        <div className="container pt-0 pb-14">
          {" "}
          {/* Removed top padding */}
          <h2 className="display-4 mb-3">How do we turn around?</h2>
          <p className="lead fs-lg mb-8">
            We know the <span className="underline"> roadmap</span> that can
            open the doors of great possibilities for all products we built!
          </p>
          <div
            className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line"
            data-cues="slideInUp"
            data-group="process"
          >
            {[
              {
                number: "01",
                title: "Ideation",
                description:
                  "We know each product starts with an idea. The most crucial aspect is knowing how to manage the idea effectively. We exhaust every thought process to bring our ideology to life on paper. In this way, your product or idea becomes a tangible reality.",
              },
              {
                number: "02",
                title: "Development",
                description:
                  "When the design matters, an idea starts taking shape for the future. We search for problems that your product will solve in real time. Based on the use case, we prepare a design and code that directly communicates with your customers.",
              },
              {
                number: "03",
                title: "Testing",
                description:
                  "We are committed to providing our customers with hassle-free, scalable, and bug-free solutions. We dedicate sufficient time to review every aspect of each solution. Once finalized, we hand over the end-to-end, market-ready solutions.",
              },
              {
                number: "04",
                title: "Maintenance",
                description:
                  "We know people love to update and maintain their excellent products. Hence we are providing simple maintenance services and letting our customers focus on the other important things in their business.",
              },
            ].map((step, index) => (
              <div className="col-md-6 col-lg-3" key={index}>
                <span
                  className={`icon btn btn-circle btn-lg ${
                    index === 1 ? "btn-primary" : "btn-soft-primary"
                  } pe-none mb-4`}
                >
                  <span className="number">{step.number}</span>
                </span>
                <h4 className="mb-1">{step.title}</h4>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}

      <section className="wrapper bg-light py-14">
        <div className="container">
          <div className="card bg-soft-primary rounded-4">
            <div className="card-body p-md-10 p-xl-11">
              <div className="row gx-lg-8 gx-xl-12 gy-10">
                <div className="col-lg-6">
                  <h3 className="display-4 mb-4">Frequently Asked Questions</h3>
                  <p className="lead fs-lg mb-0">
                    Trying to find something that is not listed above? We are
                    only a click away from you. Send us an email with your
                    query.
                  </p>
                </div>
                {/* Accordion for FAQs */}

                <div className="col-lg-6">
                  <div
                    className="accordion accordion-wrapper"
                    id="accordionExample"
                  >
                    {[
                      {
                        question:
                          "How much time will it take to develop my product?",
                        answer:
                          "The complexity and features of the products determine the timeline and cost. Therefore, if you have a list of features or a design reference, please send it to us via email. We will get back to you with a timeline ASAP.",
                      },
                      {
                        question:
                          "How much will the BytezTech developers cost?",
                        answer:
                          "We offer flexible payment terms. We offer a variety of options to accommodate our clients' financial constraints. We provide fixed-price costing models, hourly basis models, dedicated hiring, and long-term development support. Please reach out to us to receive a quote for our development services.",
                      },
                      {
                        question:
                          "I am not aware of the technology. What should I do?",
                        answer:
                          "Our expert developers have a decade of experience, and they can develop any software with straightforward documentation and an explanation of each part. Therefore, you need not worry if you are unfamiliar with the technology; we will provide you with a solution that will delight you.",
                      },
                      {
                        question:
                          " Do you provide long-term support after development?",
                        answer:
                          "Yes, we are providing 2 months of free support post development. After that we will offer long-term support on minimal charges.",
                      },
                    ].map((faq, index) => (
                      <div key={index} className="card plain accordion-item">
                        <div className={`card-header`} id={`heading${index}`}>
                          <button
                            className={`collapsed`}
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={`collapse${index}`}
                          >
                            {faq.question}
                          </button>
                        </div>

                        {/* Accordion Collapse */}

                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <p>{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About the latest projects */}

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row align-items-center mb-10">
            <div className="col-md-8 col-lg-9 col-xl-8 col-xxl-7">
              <h2 className="display-2 mb-0">
                Great Products We Built From Scratch
              </h2>
            </div>
            <div className="col-md-4 col-lg-3 ms-md-auto text-md-end mt-5 mt-md-0">
              <Link
                to="/casestudies"
                className="btn btn-lg btn-primary btn-icon btn-icon-end mb-0"
              >
                See All Projects <i className="uil uil-arrow-up-right"></i>
              </Link>
            </div>
          </div>

          {/* First Large Project Card */}

          <div className="card bg-soft-violet mb-10">
            <div className="card-body p-12 pb-0">
              <div className="row">
                <div className="col-lg-4 pb-12 align-self-center">
                  <div className="post-category fs-15 mb-3 text-violet">
                    Web Design
                  </div>
                  <h3 className="h1 post-title mb-3">Amazing car rental</h3>
                  <p>
                    Experience ease and transparency in Dubai car rentals with
                    our innovative web solution, setting a new benchmark in
                    customer satisfaction.
                  </p>
                  <a
                    href="/amazing-car-rental"
                    className="btn btn-sm btn-violet btn-icon btn-icon-end mb-0"
                  >
                    See Project <i className="uil uil-arrow-up-right"></i>
                  </a>
                </div>
                <div className="col-lg-7 offset-lg-1 align-self-end">
                  <figure>
                    <img
                      className="img-fluid"
                      src={
                        isMobile
                          ? "./assets/img/Mobileandweb/mobile/Amazing car2.webp"
                          : "./assets/img/Mobileandweb/web/Amazing car2.webp"
                      }
                      alt="Amazing car rental"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials of customers  */}
      <section className="wrapper bg-light  ">
        <div className="container py-10 py-md-10">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 mx-auto text-center ">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                Happy Customers
              </h2>
              <h3 className="display-4 mb-6 px-xl-10 px-xxl-15">
                What Our Happy Customers Write About Us?
              </h3>
            </div>
          </div>

          <SwiperSlider
            slides={slidesData.map((slide) => (
              <div
                className="card shadow-lg border-0 mb-lg-4"
                style={{
                  maxWidth: "600px",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                }}
                key={slide.name}
              >
                <blockquote className="icon icon-top fs-lg text-center  ">
                  <p className="mb-4 ">{slide.content}</p>
                  <div
                    className="star-rating"
                    role="img"
                    aria-label={`${slide.rating} out of 5 stars`}
                  >
                    {[...Array(5)].map((_, index) => (
                      <span
                        key={index}
                        className="star"
                        style={{
                          fontSize: "1.5rem",
                          color: index < slide.rating ? "#FFD700" : "#EFBF04",
                        }}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <div className="blockquote-details justify-content-center text-center ">
                    <img
                      className="w-12 mb-3 "
                      src={slide.img}
                      alt={slide.name}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "15px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="info">
                      <h5 className="mb-1  ">{slide.name}</h5>
                      <p className="mb-0 text-muted pb-2">{slide.position}</p>
                    </div>
                  </div>
                </blockquote>
              </div>
            ))}
            pagination={{
              clickable: true,
              style: {
                marginTop: "20px",
              },
            }}
            navigation={false}
            loop={false}
            autoplay={{ delay: 5000 }}
            spaceBetween={30}
            slidesPerView={1} // Default: Mobile view with 1 slide
            breakpoints={{
              // Show 2 slides on screens wider than 768px (Tablet/Web)
              768: {
                slidesPerView: 2,
                pagination: false,
                navigation: false,
                clickable: false,
                autoplay: false,
                loop: false,
                allowTouchMove: false,
                speed: 0,
              },
            }}
          />
        </div>
      </section>

      {/*let's work together  */}

      <section className="wrapper bg-gradient-blend  mb-8">
        <div className="container pt-15 pt-md-17 pb-13 pb-md-15">
          <div className="row gx-md-8 gx-xl-12 gy-10">
            <div className="col-lg-6">
              <img
                src="./assets/img/icons/solid/email-2.svg"
                className="svg-inject icon-svg icon-svg-sm solid-duo text-purple-aqua mb-3"
                alt="Contact Icon"
              />
              <h2 className="display-3 mb-3 pe-xl-14 pe-xxl-16">
                Want to Talk About Your Project?
              </h2>
              <p className="lead fs-lg pe-xxl-12 mb-0">
                Fill out the form and let our experts handle this.
              </p>
            </div>
            <div className="col-lg-6">
              <form
                className="contact-form needs-validation"
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="messages"></div>
                <div className="row gx-4">
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_name"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="Full Name"
                        required
                        data-error="First Name is required."
                      />
                      <label htmlFor="form_name">Name *</label>
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        Please enter your name.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="jane.doe@example.com"
                        required
                        data-error="Valid email is required."
                      />
                      <label htmlFor="form_email">Email *</label>
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        Please provide a valid email address.
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-4">
                      <textarea
                        id="form_message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="Your message"
                        style={{ height: "150px" }}
                        required
                      ></textarea>
                      <label htmlFor="form_message">Message *</label>
                      <div className="valid-feedback">Looks good!</div>
                      <div className="invalid-feedback">
                        Please enter your message.
                      </div>
                    </div>
                  </div>
                  {submitted && (
                    <div>
                      <h6
                        className="mt-4 mx-1 text-start"
                        style={{ color: "green", fontSize: 12 }}
                      >
                        Submitted Successfully
                      </h6>
                    </div>
                  )}
                  <div className="col-12">
                    <input
                      type="submit"
                      className="btn btn-primary rounded-pill btn-send mb-3"
                      value="Send message"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Divider */}
        <div className="overflow-hidden">
          <div className="divider text-light mx-n2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 92.26">
              <path
                fill="currentColor"
                d="M1206,21.2c-60-5-119-36.92-291-5C772,51.11,768,48.42,708,43.13c-60-5.68-108-29.92-168-30.22-60,.3-147,27.93-207,28.23-60-.3-122-25.94-182-36.91S30,5.93,0,16.2V92.26H1440v-87l-30,5.29C1348.94,22.29,1266,26.19,1206,21.2Z"
              />
            </svg>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAndWeb;
