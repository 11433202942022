import React, { useEffect, useRef } from "react";

const Howitworks = () => {
  const rellaxShapes = useRef([]);
  const imageRef = useRef(null);

    const rellaxElements = useRef([]);
     const rellaxRefs = useRef([]);
     
     
       
         useEffect(() => {
           const handleScroll = () => {
             const scrollPosition = window.pageYOffset;
       
             rellaxElements.current.forEach((el) => {
               const speed = el.getAttribute("data-rellax-speed") || 1;
               const direction = el.getAttribute("data-rellax-direction") || "normal";
       
               const offset = direction === "reverse" ? -scrollPosition : scrollPosition;
               el.style.transform = `translateY(${offset * speed}px)`;
             });
           };
       
           window.addEventListener("scroll", handleScroll);
       
           // Cleanup function
           return () => {
             window.removeEventListener("scroll", handleScroll);
           };
         }, []);
       
         const addToRefs = (el) => {
           if (el && !rellaxElements.current.includes(el)) {
             rellaxElements.current.push(el);
           }
         };
       
       useEffect(() => {
         // Ensure page scroll starts at the top
         window.scroll({
           top: 0,
           left: 0,
         });
       }, []);
  return (
    <section className="wrapper bg-gradient-reverse-primary">
      <div className="container">
        <div className="row gy-10 gy-sm-13 gx-lg-3 mb-16 mb-md-18 align-items-center">
          <div className="col-md-8 col-lg-6 position-relative">
            <div
              className="shape bg-dot primary rellax w-17 h-21"
              data-rellax-speed="1"
              style={{ top: "-2rem", left: "-1.9rem" }}
              ref={(el) => rellaxShapes.current.push(el)}
            ></div>
            <div
              className="shape rounded bg-soft-primary rellax d-md-block"
              data-rellax-speed="0.5"
              style={{
                bottom: "-1.8rem",
                right: "-1.5rem",
                width: "85%",
                height: "90%",
              }}
              ref={(el) => rellaxShapes.current.push(el)}
            ></div>
            <figure className="rounded">
              <img
                src="./assets/img/illustrations/why-choose-us2.png"
                srcSet="./assets/img/illustrations/why-choose-us2.png 2x"
                alt="Why choose us illustration"
                ref={imageRef}
              />
            </figure>
          </div>
          <div className="col-lg-5 col-xl-4 offset-lg-1">
            <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
              Why choose us?
            </h2>
            <h3 className="display-4 mb-7">
              A few reasons why our valued customers choose us.
            </h3>
            <div className="d-flex flex-row mb-6">
              <div>
                <span className="icon btn btn-block btn-soft-primary pe-none me-5">
                  <span className="number fs-18">1</span>
                </span>
              </div>
              <div>
                <h4 className="mb-1">Tailored Growth Strategies</h4>
                <p className="mb-0">
                  Crafting unique solutions that perfectly align with your business goals.
                </p>
              </div>
            </div>
            <div className="d-flex flex-row mb-6">
              <div>
                <span className="icon btn btn-block btn-soft-primary pe-none me-5">
                  <span className="number fs-18">2</span>
                </span>
              </div>
              <div>
                <h4 className="mb-1">Technological Expertise</h4>
                <p className="mb-0">
                  Providing seasoned guidance to navigate the dynamic tech landscape effortlessly.
                </p>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <span className="icon btn btn-block btn-soft-primary pe-none me-5">
                  <span className="number fs-18">3</span>
                </span>
              </div>
              <div>
                <h4 className="mb-1">Customer-Centric Innovations</h4>
                <p className="mb-0">
                  Focusing on customer engagement to foster satisfaction and boost revenue generation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="divider text-light mx-n2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
            <path
              fill="currentColor"
              d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default Howitworks;
