import React, { useEffect, useState, useRef } from "react";
import Address from "../Address";
import { Link } from "react-router-dom";
import ModalButton from "../reactModal/ModalButton";
import ProgressBar from "progressbar.js";
import { Waypoint } from "react-waypoint";
import counterUp from "counterup2";
import { Helmet } from "react-helmet-async";
import SwiperSlider from "../hooks/swiperslider.js";

const AssetControl = () => {
  const backgroundImageStyles = {
    banner: {
      backgroundImage: "url('./assets/img/casestudy/Asset-control-banner.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    card: {
      backgroundImage: "url('./assets/img/photos/bg16.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      opacity: 0.8, // Optional: to add an overlay effect
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // for progress thing
  const progressBarRef = useRef(null);
  const [bar, setBar] = useState(null);

  useEffect(() => {
    // Check if the ref is available
    if (!progressBarRef.current) return;

    const options = {
      strokeWidth: 6, // Reduced stroke width
      trailWidth: 6, // Reduced trail width
      duration: 2000,
      easing: "easeInOut",
      color: "#E668B3", // Fuchsia color
      trailColor: "#eee",
      text: {
        style: {
          color: "#000",
          position: "absolute",
          left: "50%",
          top: "50%",
          padding: 0,
          margin: 0,
          fontSize: "40px", // Smaller font size for the percentage text
          fontWeight: "bold", // Make the percentage text bold
          transform: {
            prefix: true,
            value: "translate(-50%, -50%)",
          },
        },
        autoStyleContainer: false,
      },
      svgStyle: {
        width: "100%",
        height: "100%",
      },
    };

    // Create the progress bar instance
    const progressBar = new ProgressBar.SemiCircle(progressBarRef.current, options);

    // Store the bar instance in state
    setBar(progressBar);

    // Cleanup function
    return () => {
      progressBar.destroy();
    };
  }, []);

  const handleProgressBarEnter = () => {
    if (bar) {
      bar.animate(0.6); // 60% for the Time Saved
      bar.setText("85");
    }
  };
  // Refs for counters
  const counterRef1 = useRef(null);
  // State to track if the animation has been triggered
  const [hasAnimated, setHasAnimated] = useState(false);

  // Function to handle waypoint activation for counter animation
  const handleCounterAnimation = (ref) => {
    if (ref.current && !hasAnimated) {
      counterUp(ref.current, { duration: 1000, delay: 50 });
      setHasAnimated(true); // Set state to prevent further animation
    }
  };

  //slider thing
  const TechnologyStack = [
    {
      src: "./assets/img/icons/custom/php.svg",
      alt: "PHP",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/solid/laravel.svg",
      alt: "Laravel",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/custom/uiux.svg",
      alt: "UI/UX",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/custom/mysql.svg",
      alt: "MySQL",
      className: "icon-svg icon-svg-md",
    },
    {
      src: "./assets/img/icons/custom/googleanalytics.svg",
      alt: "Google Analytics",
      className: "icon-svg icon-svg-md",
    },
  ];

  // Asset Control Images
  const slidesData = [
    {
      src: "./assets/img/Asset-manage/Asset1-min.png",
      alt: "Amazing Car 1",
    },
    {
      src: "./assets/img/Asset-manage/Asset2-min.png",
      alt: "Amazing Car Project Image 2",
    },
    {
      src: "./assets/img/Asset-manage/Asset3-min.png",
      alt: "Amazing Car Project Image 3",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Asset Control Solutions for Businesses | BytezTech</title>
        <meta
          name="description"
          content="Asset control made simple with advanced solutions tailored to your needs. BytezTech delivers efficient and secure systems to manage and track assets effectively."
        />
        <meta name="keywords" content="Asset Control Solutions for Businesses | BytezTech" />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/Asset-manage/Asset1-min.png"
        />
        <meta property="og:title" content="Asset Control Solutions for Businesses | BytezTech" />
        <meta
          name="og:description"
          content="Asset control made simple with advanced solutions tailored to your needs. BytezTech delivers efficient and secure systems to manage and track assets effectively."
        />
        <meta property="og:url" content="https://byteztech.com/asset-control " />
        <link rel="canonical" href="https://byteztech.com/asset-control " />
        <meta name="twitter:title" content="Asset Control Solutions for Businesses | BytezTech" />
        <meta
          name="twitter:description"
          content="Asset control made simple with advanced solutions tailored to your needs. BytezTech delivers efficient and secure systems to manage and track assets effectively."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/Asset-manage/Asset1-min.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Asset Control",
            image: "https://www.byteztech.com/assets/img/Asset-manage/Asset1-min.png",
            description:
              "Asset control made simple with advanced solutions tailored to your needs. BytezTech delivers efficient and secure systems to manage and track assets effectively.",
            brand: {
              "@type": "Brand",
              name: "BytezTech",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.6",
              ratingCount: "127",
            },
          })}
        </script>
      </Helmet>
      <section
        style={backgroundImageStyles.banner}
        className="wrapper image-wrapper bg-overlay text-white"
      >
        <div className="container pt-17 pb-12 pt-md-19 pb-md-16 text-center">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link to="#" className="text-reset" rel="category">
                    Invetory management
                  </Link>
                </div>
                <h1 className="display-1 mb-3 text-white">
                  Streamlining Asset Management with the "Asset Control App"
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/challenges.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt="illustration of challanges"
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The challenge faced by Asset management system is...
              </h2>
            </div>
          </div>
          <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card me-lg-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">01</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Unorganized Records</h4>
                      <p className="mb-0">
                        Users inundated with myriad products, struggling to find trustworthy and
                        multifaceted solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-lg-13 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">02</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Inefficient Maintenance Scheduling</h4>
                      <p className="mb-0">
                        Maintenance schedules were often missed, leading to unnecessary wear and
                        tear of assets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-lg-6 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">03</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Financial Leakages</h4>
                      <p className="mb-0">
                        The inability to track assets accurately sometimes resulted in financial
                        losses due to misplaced or mismanaged assets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">The Challenge</h2>
              <p className="lead fs-lg pe-lg-5">
                Efficiently tracking asset status, facilitating repairs, and managing inventory
                levels.
              </p>
              <p>
                Managing assets in any organization can quickly turn into a cumbersome process,
                especially when the tracking methods are antiquated and inefficient. Before the
                introduction of the Asset Control App, many users struggled with keeping accurate
                records of their assets, be it their status, location, or maintenance schedules.
                This not only resulted in a considerable waste of time but also increased the
                likelihood of errors and financial losses.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pt-14 pb-14 pb-md-18">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/solutions.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt="illustration of solutions"
              />
              <h2 className="display-4 mb-4 px-lg-14">The solution we offer is ...</h2>
            </div>
          </div>

          <div className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center">
            <div className="col-lg-5 offset-lg-1">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/3d2.png"
                  srcSet="./assets/img/illustrations/3d2@2x.png 2x"
                  alt="illustration of 3d2"
                />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">The Solution</h2>
              <h3 className="display-5 mb-4">
                We bring solutions to make life easier to manage the assets.
              </h3>
              <p className="mb-6">
                Identifying this widespread problem, BytezTech stepped in to create a comprehensive
                solution that would make asset management not just easier, but more accurate and
                efficient. The Asset Control App was designed to be a one-stop destination for all
                asset management needs. This app allowed users to catalogue their assets
                meticulously by adding detailed information including name, location, and service
                status, all accessible through a barcode assigned to each asset. A feature enabling
                users to update the status of the asset (whether it is under repair, sold, or
                available) with a simple barcode scan, further streamlined the process, making asset
                management a breeze.
              </p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Unified Asset Database</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Proactive Maintenance Alerts</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Barcode Integration</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Customizable Reports</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-md-8 gy-10 align-items-center">
            <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
              <figure className="rounded">
                <img
                  className="img-fluid"
                  src="./assets/img/photos/about27.jpg"
                  srcSet="./assets/img/photos/about27@2x.jpg 2x"
                  alt="illustration of about"
                />
              </figure>
              <div
                className="card shadow-lg position-absolute d-none d-md-block"
                style={{ top: "15%", left: "-7%" }}
              >
                <div className="card-body py-4 px-5">
                  <div className="d-flex flex-row align-items-center">
                    <div>
                      <img
                        src="./assets/img/icons/solid/cloud-group.svg"
                        className="svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-3"
                        alt="illustration of cloud group"
                      />
                    </div>
                    <div>
                      <Waypoint
                        onEnter={() => handleCounterAnimation(counterRef1)}
                        bottomOffset="50%"
                      />
                      <h3 className="fs-25 counter mb-0 text-nowrap" ref={counterRef1}>
                        2000+ {/* Initial value */}
                      </h3>
                      <p className="fs-16 lh-sm mb-0 text-nowrap">Happy Customers</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card shadow-lg position-absolute text-center d-none d-md-block"
                style={{ bottom: "10%", left: "-10%" }}
              >
                <div className="card-body p-6">
                  <Waypoint onEnter={handleProgressBarEnter}>
                    <div
                      ref={progressBarRef}
                      className="progressbar semi-circle fuchsia mb-3"
                      style={{
                        position: "relative",
                        height: "90px",
                        width: "90%",
                      }}
                    />
                  </Waypoint>
                </div>
                <h4
                  className="mb-0"
                  style={{
                    fontWeight: "bold",
                    position: "relative",
                    top: "-25px", // Adjust this value for more or less space below the semi-circle
                    textAlign: "center", // Centers the text below the circle
                  }}
                >
                  Time Saved
                </h4>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                <img
                  src="./assets/img/icons/custom/results.svg"
                  className="svg-inject icon-svg icon-svg-md mb-4"
                  alt="illustration of results"
                />
                <h2 className="display-4 mb-4 px-lg-14">The result it brings...</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">The Result</h2>
              <p className="mb-6">
                The introduction of the Asset Control App revolutionized the asset management
                sector. The app became an indispensable tool in many organizations, allowing them to
                maintain a seamless log of their assets, hence saving time and reducing financial
                leakages. The proactive maintenance alerts significantly increased the longevity of
                assets, contributing to substantial savings in the long run. Moreover, users could
                now handle asset sales and services with increased efficiency and transparency,
                thereby fostering trust and reliability.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-primary">
                <li>
                  <i className="uil uil-check"></i>
                  <span className="result-span">Time & Financial Savings: </span>
                  Users noticed a significant reduction in time spent managing assets and a decrease
                  in financial losses.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span className="result-span">Enhanced Asset Longevity: </span>
                  Established a harmonious ecosystem where dealers and users thrive together,
                  enhancing product reach and customer satisfaction.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span className="result-span">Boost in Operational Efficiency: </span>
                  The organizations using the app witnessed an uptick in operational efficiency,
                  streamlining processes like never before.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <p className="text-center display-3 mb-8">Technology Stack</p>
          <SwiperSlider
            slides={TechnologyStack.map((tech, index) => (
              <img
                key={index}
                src={tech.src}
                alt={tech.alt}
                className={tech.className}
                style={{
                  width: "52px", // Set a fixed width for the images
                  height: "auto", // Maintain aspect ratio
                  margin: "auto", // Center images
                }}
              />
            ))}
            spaceBetween={50} // Space between slides
            slidesPerView={7} // Number of visible slides
            loop={true} // Enable looping
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={false} // Disable dots (pagination)
            breakpoints={{
              320: { slidesPerView: 2, spaceBetween: 10 },
              640: { slidesPerView: 3, spaceBetween: 20 },
              1024: { slidesPerView: 5, spaceBetween: 30 },
            }}
            nav={false} // Disable navigation buttons
          />
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-13 pb-md-15">
          <div
            className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-300 mb-14"
            style={backgroundImageStyles.card}
          >
            <div className="card-body p-10 p-xl-12">
              <div className="row text-center">
                <div className="col-xl-11 col-xxl-9 mx-auto">
                  <h2 className="fs-16 text-uppercase text-white mb-3">Book a free consultation</h2>
                  <h3 className="display-3 mb-8 px-lg-8 text-white">
                    Unlock personalized advice with a{" "}
                    <span className="underline-3 style-2 yellow">complimentary consultation.</span>{" "}
                    Book your slot now!
                  </h3>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <span>
                  <ModalButton buttonValue={"Book a free consultation"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="wrapper bg-light">
        <div className="container-fluid px-md-6">
          <div
            className="swiper-container swiper-height blog grid-view mb-17 mb-md-19"
            data-margin="30"
            data-nav="true"
            data-dots="true"
            data-items-xxl="2"
            data-items-md="2"
            data-items-xs="1"
          >
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img
                      src="./assets/img/Asset-manage/Asset1-min.png"
                      alt="illustration of assets 1"
                    />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img
                      src="./assets/img/Asset-manage/Asset2-min.png"
                      alt="illustration of assets 2"
                    />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img
                      src="./assets/img/Asset-manage/Asset3-min.png"
                      alt="illustration of assests 3 min"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
     </section> */}

      <section className="wrapper bg-light mb-lg-15">
        <div className="container-fluid px-md-6 mb-lg-6">
          <SwiperSlider
            slides={slidesData.map((slide, index) => (
              <div
                key={index}
                className="swiper-slide"
                style={{
                  display: "flex",
                  paddingBottom: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <figure className="rounded">
                  <img
                    src={slide.src}
                    alt={slide.alt}
                    className="w-100"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </figure>
              </div>
            ))}
            spaceBetween={30}
            slidesPerView={2}
            pagination={{
              clickable: true,
              type: "bullets",
              renderBullet: function (index, className) {
                return `<span class="${className}"></span>`;
              },
            }}
            navigation={true}
            loop={false} // Important: set to false to match exact slide count
            autoplay={{ delay: 5000 }}
            nav={true}
            breakpoints={{
              320: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
            }}
          />
        </div>
      </section>
      <Address />
    </>
  );
};

export default AssetControl;
