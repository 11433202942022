import React from "react";
import ModalButton from "./reactModal/ModalButton";

const Hiwabout = () => {
  return (
    <section className="wrapper bg-gray">
      <div className="container py-14 py-md-16">
        <div className="row mb-5">
          <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
            <img
              src="./assets/img/icons/lineal/list.svg"
              className="svg-inject icon-svg icon-svg-md mb-4"
              alt="list logo"
            />
            <h2 className="display-4 mb-4 px-lg-14">
              Here are 3 working steps to organize our business projects.
            </h2>
          </div>
        </div>
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div className="card me-lg-6">
              <div className="card-body p-6">
                <div className="d-flex flex-row">
                  <div>
                    <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                      <span className="number">01</span>
                    </span>
                  </div>
                  <div>
                    <h4 className="mb-1">Define Clear Objectives</h4>
                    <p className="mb-0">
                    Establish and align with the project's goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ms-lg-13 mt-6">
              <div className="card-body p-6">
                <div className="d-flex flex-row">
                  <div>
                    <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                      <span className="number">02</span>
                    </span>
                  </div>
                  <div>
                    <h4 className="mb-1">Allocate Resources Efficiently</h4>
                    <p className="mb-0">
                    Prioritize manpower, technology, and budget allocation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mx-lg-6 mt-6">
              <div className="card-body p-6">
                <div className="d-flex flex-row">
                  <div>
                    <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                      <span className="number">03</span>
                    </span>
                  </div>
                  <div>
                    <h4 className="mb-1">Regular Progress Monitoring</h4>
                    <p className="mb-0">Track, assess, and adjust for optimal results.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h2 className="display-6 mb-3">How It Works?</h2>
            <p className="lead fs-lg pe-lg-5">
              Find out everything you need to know and more about how we create
              our business process models.
            </p>
            <p>
            Dive into our meticulous approach to business process modeling. With seasoned expertise, we analyze your unique challenges, craft tailor-made solutions, and promise sustainable growth.
            </p>
            <p className="mb-6">
            Every model is a testament to precision, innovation, and dedication to your success. Elevate your operations with our strategic insights and dynamic methodologies. Choose excellence; choose us.
            </p>
            <ModalButton buttonValue={"Get a free quote"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hiwabout;
